import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { ModelMixin } from '../core/base-generic.model';
import { RL1PackageType } from './rl1.model';
import { T4Summary } from './t4-summary.model';
import { TaxReturnContactInfo, TaxReturnSummary } from './tax-return-summary.abstract.model';

type RL1SummaryState = 'open' | 'issued';
type RL1TransmitterState = 'new' | 'open' | 'processing' | 'pending' | 'complete' | 'rejected' | 'error';

export class RL1Summary extends ModelMixin<RL1Summary, string>() implements TaxReturnSummary {
    protected static _resource = 'payroll/companies/:company/rl1Summaries';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    protected static _serializeAttributes = ['year', 'taxReturnId', 'defaultContact'];

    private _stateBadgeMap: Omit<Record<RL1TransmitterState, BadgeType>, 'new'> = {
        open: 'informative',
        processing: 'success',
        pending: 'success',
        complete: 'success',
        error: 'danger',
        rejected: 'danger',
    };

    isRL1Summary(): this is RL1Summary {
        return true;
    }

    isT4Summary(): this is T4Summary {
        return false;
    }

    get rqConfirmationNumber(): string | null {
        return this._attributes['rqConfirmationNumber'];
    }

    get rqFormRl1TransmitterId(): string {
        return this._attributes['rqFormRl1TransmitterId'];
    }

    get hasConfirmedManualSubmission(): boolean {
        return this._attributes['hasConfirmedManualSubmission'];
    }

    set hasConfirmedManualSubmission(hasConfirmedManualSubmission: boolean) {
        this._attributes['hasConfirmedManualSubmission'] = hasConfirmedManualSubmission;
    }

    get totEmpeQppAmt(): number {
        return this._attributes['totEmpeQppAmt'];
    }

    get totEmprQppAmt(): number {
        return this._attributes['totEmprQppAmt'];
    }

    get totEmpeQppeAmt(): number {
        return this._attributes['totEmpeQppeAmt'];
    }

    get totEmprQppeAmt(): number {
        return this._attributes['totEmprQppeAmt'];
    }

    get totEmpeQpipAm(): number {
        return this._attributes['totEmpeQpipAmt'];
    }

    get totEmprQpipAm(): number {
        return this._attributes['totEmprQpipAmt'];
    }

    get totItxDdctAmt(): number {
        return this._attributes['totItxDdctAmt'];
    }

    get year(): number {
        return this._attributes['year'];
    }

    get state(): RL1SummaryState {
        return this._attributes['state'];
    }

    get rl1TransmitterState(): RL1TransmitterState {
        return this._attributes['rl1TransmitterState'];
    }

    set rl1TransmitterState(rl1TransmitterState: RL1TransmitterState) {
        this._attributes['rl1TransmitterState'] = rl1TransmitterState;
    }

    set state(state: RL1SummaryState) {
        this._attributes['state'] = state;
    }

    get isOpen(): boolean {
        return (
            this.state === 'open' || !this.submittedAt || ['new', 'open', 'error'].includes(this.rl1TransmitterState)
        );
    }

    get canSubmit(): boolean {
        return !!this._attributes['canSubmit'];
    }

    get typeOfPackage(): RL1PackageType {
        return this._attributes['typeOfPackage'];
    }

    get isAmendment(): boolean {
        return this.typeOfPackage === RL1PackageType.AMENDED;
    }

    get isErrored(): boolean {
        return this.rl1TransmitterState === 'error';
    }

    get isComplete(): boolean {
        return this.rl1TransmitterState === 'complete';
    }

    get isSubmitted(): boolean {
        return (
            this.state === 'issued' ||
            !!this.submittedAt ||
            ['pending', 'processing', 'complete', 'rejected'].includes(this.rl1TransmitterState)
        );
    }

    get submittedAt(): string | null {
        return this._attributes['submittedAt'];
    }

    get completedAt(): string | null {
        return this._attributes['completedAt'];
    }
    set completedAt(val: string | null) {
        this._attributes['completedAt'] = val;
    }

    get taxReturnId(): string | null {
        return this._attributes['taxReturnId'];
    }

    set defaultContact(contactInfo: TaxReturnContactInfo) {
        this._attributes['defaultContact'] = contactInfo;
    }

    get stateBadge(): BadgeDisplay | undefined {
        if (!this.id || this.rl1TransmitterState === 'new') {
            return undefined;
        }

        const label = `payroll.taxReturns.rl1SummaryStatuses.${this.rl1TransmitterState}`;

        return { label, type: this.rl1TransmitterState ? this._stateBadgeMap[this.rl1TransmitterState] : 'default' };
    }

    get manualSelectedAt(): string {
        return this._attributes['manualSelectedAt'];
    }

    /**
     * Whether individual RL1 e-mails can be sent to employees
     */
    get canSendEmails(): boolean {
        // E-mails can only be sent after they've been processed by RQ, therefore we wait for a complete state
        return this.isComplete;
    }
}

import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Optional, Output, Self } from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
    BaseAutocompleteComponent,
    Option,
} from '@app/components/platform/base-autocomplete/base-autocomplete.component';
import { AuthService } from '@app/services';
import { FetchTemplates } from '@forms/performance/components/survey/details/fetch-templates-for-survey-creation.service';
import { Template } from '@models/performance/template.model';
import { filter } from 'rxjs/operators';

type SurveyTemplateValue = Template | primitive | null;

@Component({
    selector: 'ui-survey-template-autocomplete',
    templateUrl: './survey-template-autocomplete.template.html',
    styleUrls: ['./survey-template-autocomplete.styles.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: SurveyTemplateAutocompleteComponent }, FetchTemplates],
})
export class SurveyTemplateAutocompleteComponent extends BaseAutocompleteComponent<SurveyTemplateValue> {
    @Output() templatesLoaded: EventEmitter<Template[]> = new EventEmitter<Template[]>();
    controlType = 'survey-template-autocomplete';
    id = `survey-template-autocomplete-${SurveyTemplateAutocompleteComponent.nextId++}`;
    templates: Option<SurveyTemplateValue>[] = [];
    options: Option<SurveyTemplateValue>[] = [];

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor,
        private readonly auth: AuthService,
        private readonly fetchTemplates: FetchTemplates
    ) {
        super(ngControl, _parentForm, _defaultErrorStateMatcher, elementRef, fm);

        const searchControlSub = this.searchControl.valueChanges
            .pipe(filter((searchTerm) => typeof searchTerm === 'string'))
            .subscribe((searchTerm: string) => {
                this._setDisplayOptions(searchTerm);
            });

        this._subscriptions.push(searchControlSub);
    }

    async ngOnInit(): Promise<void> {
        this._inputElement = this.elementRef.nativeElement.querySelector('input');

        this.loading = true;
        const [templates] = await this.fetchTemplates.forCompany(this.auth.company);
        this.templatesLoaded.emit(templates);
        this.templates = templates.map((template: Template) => {
            return {
                label: template.name,
                value: template,
            };
        });

        this._setDisplayOptions();
    }

    onBlur(): void {
        /**
         * Wrapped in a setTimeout because there is a race condition with selected() event. We want selected()
         * to process before this.
         */
        setTimeout(() => {
            this.writeValue(this.value);
            this.blur.emit();

            if (typeof this.value === 'undefined') {
                this.trigger.closePanel();
                this._setDisplayOptions();
            }
        }, 250);
    }

    displayFn = (selection: SurveyTemplateValue): string => {
        return this.templates?.find((template) => template.value == selection)?.label ?? '';
    };

    protected _setDisplayOptions(query = ''): void {
        let templates = this.templates;

        if (query) {
            templates = templates.filter((template) =>
                template.label.toLowerCase().includes(query.toString().toLowerCase())
            );
        }

        this.options = templates;

        this.loading = false;
    }
}

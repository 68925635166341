import { ActivatedRoute } from '@angular/router';
import { FeatureFlag } from '@app/enums';
import { UserAbilities } from '@app/interfaces';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import { AbilityService, AuthService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { TranslateService } from '@ngx-translate/core';

export enum MenuItemNames {
    benefitsUpsell,
    inAppUpsell,
}

export type MenuItemGroup = MenuItem[];

/**
 * ShowWhenServices are the services passed to the showWhen function
 * Used for determining menu values
 */
export type ShowWhenServices = {
    authService: AuthService;
    featureService: FeatureService;
    abilityService: AbilityService;
    activateRoute: ActivatedRoute;
    onboardingStatusService: OnboardingStatusService;
    translateService: TranslateService;
};

export interface MenuItem {
    /**
     * An optional name used to refer to the item in other
     * places in code.
     */
    name?: MenuItemNames;
    /**
     * Label to show to the user
     */
    key: string;
    /**
     * Link conforming to Angular's link format
     */
    link: string[];
    /**
     * Show menu item only if the user's company has access to the listed module.
     */
    children?: MenuItem[];
    /**
     * Show if the user has any of the permissions in the array
     */
    can?: string[];
    /**
     * Show if the user has none of the permission in the array
     */
    cannot?: string[];
    /**
     * Show if has the feature flag
     */
    showIfHasFeatureFlag?: FeatureFlag;
    /**
     * Do not show if has the feature flag
     */
    hideIfHasFeatureFlag?: FeatureFlag;
    /**
     * Show if the user has the ability as defined in the AbilityService
     */
    ableTo?: keyof UserAbilities;
    /**
     * Show if the user does not have the ability as defined in the AbilityService
     */
    unableTo?: keyof UserAbilities;
    /**
     * Show if the user has any of the permissions in the array
     * unless `permissionAll` is true
     */
    permission?: string[];
    /**
     * Dictates whether the permissions array requires all permissions
     */
    permissionAll?: boolean;
    /**
     * For links that should open in a new tab
     */
    openInNewTab?: boolean;
    /**
     * For the Admin SimplyBenefits Portal link
     */
    showIfCompanyHasSimplyBenefits?: boolean;
    /**
     * For the Employee SimplyBenefits Portal link
     */
    showIfCompanyAndEmployeeHaveSimplyBenefits?: boolean;
    /**
     * Seems to do the same as permissionAll but only in permission guard
     */
    checkAllPermissions?: boolean;
    /**
     * Show if the user's company has this module
     */
    module?: string;
    /**
     * Show if the user's employee has this module
     */
    employeeModule?: string;
    /**
     * Show if the user's company does not have this module
     */
    showWhenModuleDisabled?: string;
    /**
     * showWhen is a function that will be passed key services and decide
     * if the item should be shown.
     * This is a generalized way of checking if a menu item should be shown.
     * Add services as needed.
     */
    showWhen?: (services: ShowWhenServices) => boolean;
    /**
     * Show an indicator if the value is true.
     * Should be set dynamically after the menu is instantiated.
     */
    showIndicator?: boolean;
    queryParams?: Record<string, unknown>;
    routerLinkExact?: boolean | 'ignore-query-params';
    checkAccessToEmployee?: boolean;
    hideIfNoTenantId?: boolean;
}

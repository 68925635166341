import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { AuthService, FileHelperService, NotifyService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class DeductionEmployeesCsvService {
    constructor(
        private fileHelper: FileHelperService,
        private auth: AuthService,
        private http: HttpClient,
        private notify: NotifyService
    ) {}

    async downloadTemplate(deductionId: number): Promise<void> {
        const templatePath = this.downloadTemplatePath(deductionId);
        return new Promise((resolve, reject) => {
            this.fileHelper.savePayrollFile(
                templatePath,
                `deductions-employees-template`,
                'csv',
                null,
                (e?: Record<string, unknown>) => {
                    if (e) {
                        this.notify.error(ErrorParser.parse(e));
                        reject(e);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    private downloadTemplatePath(deductionId: number): string {
        return `${this.auth.company.id}/deductions/${deductionId}/downloadCsvTemplate`;
    }

    async upload(deductionId: number, file: File): Promise<boolean> {
        const uploadData = new FormData();
        uploadData.append('file', file);
        const url = PayrollResources.DeductionCsvImporter.replace(
            ':companyId',
            this.auth.company.id.toString()
        ).replace(':deductionId', deductionId.toString());

        try {
            const uploadEmployeesCsvResultPromise = await this.http.post(url, uploadData).toPromise();
            const uploadEmployeesCsvResult = uploadEmployeesCsvResultPromise['data']['attributes'];

            const message = uploadEmployeesCsvResult?.message;

            if (!!message && message.includes('Updated')) {
                this.notify.success(message);
                return true;
            } else {
                this.notify.warn(message || 'Error with submission');
                return false;
            }
        } catch (e) {
            if (e instanceof HttpErrorResponse) throw e.message;

            throw e;
        }
    }
}

<div
    class="translations-provider"
    [style]="{
        '--rich-text-editor-normal-text': '&quot;' + ('components.richTextEditor.normalText' | translate) + '&quot;',
        '--rich-text-editor-heading1-text': '&quot;' + ('components.richTextEditor.heading1' | translate) + '&quot;',
        '--rich-text-editor-heading2-text': '&quot;' + ('components.richTextEditor.heading2' | translate) + '&quot;',
        '--rich-text-editor-heading3-text': '&quot;' + ('components.richTextEditor.heading3' | translate) + '&quot;',
        '--rich-text-editor-enter-link-text': '&quot;' + ('components.richTextEditor.enterLink' | translate) + '&quot;',
        '--rich-text-editor-save-text': '&quot;' + ('components.richTextEditor.save' | translate) + '&quot;',
    }"
>
    <div
        class="editor"
        [class.no-toolbar]="!displayToolbar"
        (click)="onTouched()"
        [ngStyle]="{ height: height }"
        #container
    ></div>
</div>

var buildFormattingTokensRegExp = require('../../_lib/build_formatting_tokens_reg_exp/index.js')

function buildFormatLocale () {
  var months3char = ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juill.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
  var monthsFull = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
  var weekdays2char = ['di', 'lu', 'ma', 'me', 'je', 've', 'sa']
  var weekdays3char = ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.']
  var weekdaysFull = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
  var meridiemUppercase = ['AM', 'PM']
  var meridiemLowercase = ['am', 'pm']
  var meridiemFull = ['du matin', 'de l’après-midi', 'du soir']

  var formatters = {
    // Month: Jan, Feb, …, Dec
    'MMM': function (date) {
      return months3char[date.getMonth()]
    },

    // Month: January, February, …, December
    'MMMM': function (date) {
      return monthsFull[date.getMonth()]
    },

    // Day of week: Su, Mo, …, Sa
    'dd': function (date) {
      return weekdays2char[date.getDay()]
    },

    // Day of week: Sun, Mon, …, Sat
    'ddd': function (date) {
      return weekdays3char[date.getDay()]
    },

    // Day of week: Sunday, Monday, …, Saturday
    'dddd': function (date) {
      return weekdaysFull[date.getDay()]
    },

    // AM, PM
    'A': function (date) {
      return (date.getHours() / 12) >= 1 ? meridiemUppercase[1] : meridiemUppercase[0]
    },

    // am, pm
    'a': function (date) {
      return (date.getHours() / 12) >= 1 ? meridiemLowercase[1] : meridiemLowercase[0]
    },

    // a.m., p.m.
    'aa': function (date) {
      var hours = date.getHours()

      if (hours <= 12) {
        return meridiemFull[0]
      }

      if (hours <= 16) {
        return meridiemFull[1]
      }

      return meridiemFull[2]
    },

    // ISO week, ordinal version: 1st, 2nd, …, 53rd
    // NOTE: Week has feminine grammatical gender in French: semaine
    'Wo': function (date, formatters) {
      return feminineOrdinal(formatters.W(date))
    }
  }

  // Generate ordinal version of formatters: M → Mo, D → Do, etc.
  // NOTE: For words with masculine grammatical gender in French: mois, jour, trimestre
  var formatterTokens = ['M', 'D', 'DDD', 'd', 'Q']
  formatterTokens.forEach(function (formatterToken) {
    formatters[formatterToken + 'o'] = function (date, formatters) {
      return masculineOrdinal(formatters[formatterToken](date))
    }
  })

  // Special case for day of month ordinals in long date format context:
  // 1er mars, 2 mars, 3 mars, …
  // See https://github.com/date-fns/date-fns/issues/437
  //
  // NOTE: The below implementation works because parsing of tokens inside a
  // format string is done by a greedy regular expression, i.e. longer tokens
  // have priority. E.g. formatter for "Do MMMM" has priority over individual
  // formatters for "Do" and "MMMM".
  var monthsTokens = ['MMM', 'MMMM']
  monthsTokens.forEach(function (monthToken) {
    formatters['Do ' + monthToken] = function (date, commonFormatters) {
      var dayOfMonthToken = date.getDate() === 1
        ? 'Do'
        : 'D'
      var dayOfMonthFormatter = formatters[dayOfMonthToken] || commonFormatters[dayOfMonthToken]

      return dayOfMonthFormatter(date, commonFormatters) + ' ' + formatters[monthToken](date)
    }
  })

  return {
    formatters: formatters,
    formattingTokensRegExp: buildFormattingTokensRegExp(formatters)
  }
}

function masculineOrdinal (number) {
  if (number === 1) {
    return '1er'
  }

  return number + 'e'
}

function feminineOrdinal (number) {
  if (number === 1) {
    return '1re'
  }

  return number + 'e'
}

module.exports = buildFormatLocale

<input
    type="text"
    [placeholder]="placeholder"
    [attr.aria-label]="ariaLabel"
    [class.read-only]="readOnly"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    (blur)="onBlur()"
    [errorStateMatcher]="errorStateMatcher"
/>
<mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="selected($event)"
    panelWidth="auto"
    [displayWith]="displayFn"
>
    <mat-option *ngFor="let option of options" [value]="option.value" [class.has-sub-label]="!!option.subTitle">
        <div class="option-wrapper">
            <ng-container *ngIf="option.avatarable">
                <img
                    class="avatar margin-right-1"
                    *ngIf="option.hasAvatar"
                    alt="image"
                    [src]="getAvatarUrl(option.value) | secure"
                />
                <span
                    class="avatar placeholder flex justify-center align-items-center margin-right-1"
                    aria-hidden="true"
                    *ngIf="!option.hasAvatar"
                    >{{ option.avatarLabel }}</span
                >
            </ng-container>
            <span class="text">
                @if (option.shouldTranslate) {
                    {{ option.label | translate }}
                } @else {
                    <span>{{ option.label }}</span>
                }
                <span class="sub-label">{{ option.subTitle }}</span>
            </span>
            <ui-badge *ngIf="option.status" class="margin-left-2" [type]="option.status.type">
                {{ option.status.label | translate }}
            </ui-badge>
        </div>
    </mat-option>
</mat-autocomplete>

<ui-badge
    *ngIf="!focused && getValueOptionStatus()"
    class="input-status"
    [ngStyle]="{ left: inputStatusOffset + 'px' }"
    [type]="getValueOptionStatus().type"
>
    {{ getValueOptionStatus().label | translate }}
</ui-badge>
<img *ngIf="loading" class="loading" appIcon="Spinner" />

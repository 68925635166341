import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';

export type PersonaInquiryDetails = {
    status: PersonaInquiryStatus;
    inquiryId: string;
};

/**
 * Possible inquiry statuses returned from Persona
 */
export type PersonaInquiryStatus =
    | 'created'
    | 'pending'
    | 'completed'
    | 'expired'
    | 'failed'
    | 'needs_review'
    | 'approved'
    | 'declined';

/**
 * Possible inquiry statuses returned from Humi
 */
export type HumiInquiryStatus = 'not_complete' | 'in_progress' | 'complete';

/**
 * When starting a Persona Session from scratch, the following attributes are required
 */
export type NewPersonaSessionAttributes = {
    templateId: string;
    referenceId: string;
    environmentId: string;
};

/**
 * When resuming an existing Persona Session, the following attributes are required
 */
export type ResumePersonaSessionAttributes = {
    inquiryId: string;
    sessionToken: string;
};

/**
 * These statuses rely on intervention from a 3rd-party. Therefore we want to ignore the cache if an inquiry is in these statuses so we always get a live reflection of whether it's been actioned on
 */
export const INVALIDATE_CACHE_STATUSES: PersonaInquiryStatus[] = ['failed', 'needs_review'];

/**
 * Each KYC/KYB type has its own pre-redirect page under different modules.
 * This mapping both informs the link within the bar as well as informs when to hide the bar
 */
export const KYC_KYB_URLS: Record<PersonaScope, string> = {
    KYC: '/account/verify-your-identity',
    KYB: '/company/verify-your-business',
} as const;

/**
 * Fields that we allow to be pre-filled into Persona for KYC prior to launching
 */
type KYCPrefillField = 'selected_country_code' | 'email_address' | 'name_first' | 'name_last';
/**
 * Fields that we allow to be pre-filled into Persona for KYB prior to launching
 */
export type KYBPrefillField =
    | 'business_name'
    | 'business_tax_identification_number'
    | 'incorporation_year'
    | 'business_physical_address_street_1'
    | 'business_physical_address_street_2'
    | 'business_physical_address_city'
    | 'business_physical_address_subdivision'
    | 'business_physical_address_postal_code'
    | 'business_physical_address_country_code'
    | 'form_filler_is_signing_officer'
    | 'form_filler_name_first'
    | 'form_filler_name_last'
    | 'form_filler_job_title'
    | 'form_filler_email_address'
    | 'initiator_employee_id'
    | 'signing_officer_api_endpoint';

/**
 * Record of pre-filled data for KYC to be sent to Persona
 */
export type KYCPrefill = Partial<Record<KYCPrefillField, string>>;
/**
 * Record of pre-filled data for KYB to be sent to Persona
 */
export type KYBPrefill = Partial<Record<KYBPrefillField, string | number>>;

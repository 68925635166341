import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { JsonApiResponse } from '@app/interfaces';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Paystub } from '@app/models/payroll/company/paystub.model';
import { Payroll } from '@app/models/payroll/payroll.model';
import { AuthService, NotifyService } from '@app/services';
import { Employee } from '@models/employee/employee.model';
import { PayrollResources } from '@payroll/payroll.resources';

@Injectable()
export class PayrollService {
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private notify: NotifyService
    ) {}

    async get(payrollId: number): Promise<Payroll | undefined> {
        try {
            return await Payroll.param('company', this.auth.company.id)
                .with(['pay_periods', 'ept_summary', 'covid_wage_subsidy', 'content_directories'])
                .find(payrollId);
        } catch (e) {
            this.notify.error(ErrorParser.parse(e));
        }
    }

    async addPaystub(employee: Employee, payroll: Payroll): Promise<void> {
        if (!employee.isPayrollSyncEnabled) {
            throw new Error('This employee is not on payroll');
        }

        const paystub = new Paystub({
            payrollId: payroll.id,
            employeeId: employee.id,
        });

        await paystub.param('payroll', payroll.id).save();
    }

    async asyncRun(payroll: Payroll): Promise<JsonApiResponse<null>> {
        return this.http
            .put<
                JsonApiResponse<null>
            >(PayrollResources.RunPayrollAsync.replace(':company', this.auth.company.id.toString()).replace(':payroll', payroll.id.toString()), {})
            .toPromise();
    }

    async asyncRunHours(payroll: Payroll): Promise<JsonApiResponse<null>> {
        return this.http
            .put<
                JsonApiResponse<null>
            >(PayrollResources.RunPayrollHoursAsync.replace(':company', this.auth.company.id.toString()).replace(':payroll', payroll.id.toString()), {})
            .toPromise();
    }

    async reset(payroll: Payroll): Promise<JsonApiResponse<null>> {
        return this.http
            .put<
                JsonApiResponse<null>
            >(PayrollResources.ResetPayroll.replace(':company', this.auth.company.id.toString()).replace(':payroll', payroll.id.toString()), {})
            .toPromise();
    }

    async asyncPay(payroll: Payroll): Promise<JsonApiResponse<unknown>> {
        return this.http
            .put<
                JsonApiResponse<unknown>
            >(PayrollResources.PayPayrollAsync.replace(':company', this.auth.company.id.toString()).replace(':payroll', payroll.id.toString()), {})
            .toPromise();
    }

    offCycleCustomEmployeeQuery(): QueryFetcher {
        return Employee.param('company', this.auth.company.id)
            .where('onPayroll', true)
            .where('status', ['active', 'onboarding', 'terminated', 'on leave'])
            .orderBy('status', 'ASC');
    }
}

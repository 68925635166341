/* eslint-disable camelcase */
import { KeyValue } from '@angular/common';
import { formatDate } from '@app/functions/date-format';
import { TranslatableKey } from '@app/types/translatable.type';
import { setMonth } from 'date-fns';
import moment from 'moment';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const BIWEEKLY = 'bi-weekly';
export const SEMIMONTHLY = 'semi-monthly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const SEMIANNUALLY = 'semi-annually';
export const ANNUALLY = 'annually';

export const AccrualFrequencies: { [frequency: string]: number } = {
    daily: 365,
    weekly: 52,
    'bi-weekly': 26,
    'semi-monthly': 24,
    monthly: 12,
    quarterly: 4,
    // 'semi-annually': 2, // Not supporting this at this time
    annually: 1,
};

// Function to sort keys according to the specified order
export function CompareAccrualFrequencies(a: KeyValue<string, number>, b: KeyValue<string, number>): number {
    const order = [DAILY, WEEKLY, BIWEEKLY, SEMIMONTHLY, MONTHLY, QUARTERLY, ANNUALLY];
    return order.indexOf(a.key) - order.indexOf(b.key);
}

export const SemiMonthlyIntervals: { firstDay: number; secondDay: number }[] = [
    { firstDay: 1, secondDay: 15 },
    { firstDay: 1, secondDay: 16 },
    { firstDay: 2, secondDay: 16 },
    { firstDay: 3, secondDay: 17 },
    { firstDay: 4, secondDay: 18 },
    { firstDay: 5, secondDay: 19 },
    { firstDay: 6, secondDay: 20 },
    { firstDay: 7, secondDay: 21 },
    { firstDay: 8, secondDay: 22 },
    { firstDay: 9, secondDay: 23 },
    { firstDay: 10, secondDay: 24 },
    { firstDay: 11, secondDay: 25 },
    { firstDay: 12, secondDay: 26 },
    { firstDay: 13, secondDay: 27 },
    { firstDay: 14, secondDay: 28 },
    { firstDay: 15, secondDay: -1 },
];

export const MonthlyIntervals: number[] = [...Array(29).keys(), -1].slice(1);

const getQuarterlyPeriodsLabel = (monthsInQuarter: number[], locale: string): string =>
    monthsInQuarter.map((monthNum) => formatDate(setMonth(new Date(), monthNum), 'shortOnlyMonth', locale)).join(', ');

export const QuarterlyPeriods: {
    firstQuarter: number;
    secondQuarter: number;
    thirdQuarter: number;
    fourthQuarter: number;
    label: { en_CA: string; fr_CA: string };
}[] = [
    { firstQuarter: 0, secondQuarter: 3, thirdQuarter: 6, fourthQuarter: 9 },
    { firstQuarter: 1, secondQuarter: 4, thirdQuarter: 7, fourthQuarter: 10 },
    { firstQuarter: 2, secondQuarter: 5, thirdQuarter: 8, fourthQuarter: 11 },
].map((quarter) => ({
    ...quarter,
    label: {
        en_CA: getQuarterlyPeriodsLabel(Object.values(quarter), 'en_CA'),
        fr_CA: getQuarterlyPeriodsLabel(Object.values(quarter), 'fr_CA'),
    },
}));

export const MonthsForDropdown: { label: string; value: number }[] = moment.months().map((month, index) => {
    return { label: month, value: index };
});

export const TimeOffDateFormatSlashes = 'DD/MM/YYYY';
export const TimeOffDateFormatDashes = 'YYYY-MM-DD';
export const TimeOffDateFormatMonthDayYear = 'MMMM D, YYYY';
export const TimeOffDateFormatShortMonthDayYear = 'MMM DD, YYYY';
export const TimeOffDateFormatMonthDay = 'MMMM D';
export const TimeOffDateFormatMonthDayComma = 'MMM, D';
export const TimeOffDateFormatDayMonthDashes = 'MM-DD';

// These formats are for the angular date pipe
export const TimeOffDateMonthDayYearDatePipeFormat = 'MMMM d, y';
export const TimeOffDateMonthDayDateShortCommaPipeFormat = 'MMM, d';
export const TimeOffDateMonthDayDatePipeFormat = 'MMMM d';
export const TimeOffDateMonthDayDateShortPipeFormat = 'MMM d';

export const ListOfAccrualPreviewParams: string[] = [
    'isUnlimited',
    'accrualTiming',
    'accrualFrequency',
    'dayOfMonthOne',
    'dayOfMonthTwo',
    'startOfWeek',
    'weeklyFrequenciesAnchor',
    'dayOfYearOne',
    'dayOfYearTwo',
    'dayOfYearThree',
    'dayOfYearFour',
];

export const Days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const DaysKeys: { value: string; translationString: TranslatableKey }[] = [
    {
        value: 'Monday',
        translationString: 'time-off-v3.policyComponents.accrualCard.monday',
    },
    {
        value: 'Tuesday',
        translationString: 'time-off-v3.policyComponents.accrualCard.tuesday',
    },
    {
        value: 'Wednesday',
        translationString: 'time-off-v3.policyComponents.accrualCard.wednesday',
    },
    {
        value: 'Thursday',
        translationString: 'time-off-v3.policyComponents.accrualCard.thursday',
    },
    {
        value: 'Friday',
        translationString: 'time-off-v3.policyComponents.accrualCard.friday',
    },
    {
        value: 'Saturday',
        translationString: 'time-off-v3.policyComponents.accrualCard.saturday',
    },
    {
        value: 'Sunday',
        translationString: 'time-off-v3.policyComponents.accrualCard.sunday',
    },
];

export const END_OF_MONTH = -1;

export const LAST_DAY_OF_THE_MONTH = -1;

// We only do 28 to help us avoid cases with leap years
export const NUMBER_OF_DAYS_IN_MONTH = 28;

export const DECIMAL_PLACES = 2;

export const DECIMAL_PLACES_FOR_PIPE = '1.2-2';

export const WEEK_DAYS_SHORT_FORMAT = [
    'time-off-v3.yearCalendar.sun',
    'time-off-v3.yearCalendar.mon',
    'time-off-v3.yearCalendar.tue',
    'time-off-v3.yearCalendar.wed',
    'time-off-v3.yearCalendar.thu',
    'time-off-v3.yearCalendar.fri',
    'time-off-v3.yearCalendar.sat',
];

export type TimeUnit = 'hours' | 'days';

export const MONTH_SHORT = [
    'time-off-v3.yearCalendar.jan',
    'time-off-v3.yearCalendar.feb',
    'time-off-v3.yearCalendar.mar',
    'time-off-v3.yearCalendar.apr',
    'time-off-v3.yearCalendar.may',
    'time-off-v3.yearCalendar.jun',
    'time-off-v3.yearCalendar.jul',
    'time-off-v3.yearCalendar.aug',
    'time-off-v3.yearCalendar.sep',
    'time-off-v3.yearCalendar.oct',
    'time-off-v3.yearCalendar.nov',
    'time-off-v3.yearCalendar.dec',
];

export const TimeOffIcons = [
    'addRepeat',
    'birthdayCake',
    'airplane',
    'alarm',
    'book',
    'briefcase',
    'bug',
    'calendarDetail',
    'coin',
    'foot',
    'gavel',
    'gift',
    'group',
    'heart',
    'home',
    'profile',
];

export const TimeOffBalanceDateSetting = 'timeOffBalance.today';

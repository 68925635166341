/* eslint-disable camelcase */
import { Injectable, Injector } from '@angular/core';
import { KYBSigningOfficerRequiredError } from '@app/errors/kyb/kyb-signing-officer-required.error';
import { Office } from '@app/models/company/office.model';
import { Employee } from '@app/models/employee/employee.model';
import { PersonaAccount } from '@app/models/kyc-kyb/persona-account.model';
import { PersonaInquiryToken } from '@app/models/kyc-kyb/persona-inquiry-token.model';
import { PersonaInquiry } from '@app/models/kyc-kyb/persona-inquiry.model';
import { PersonaResendInvite } from '@app/models/kyc-kyb/persona-resend-invite.model';
import {
    HumiInquiryStatus,
    INVALIDATE_CACHE_STATUSES,
    KYBPrefill,
    KYCPrefill,
    NewPersonaSessionAttributes,
    ResumePersonaSessionAttributes,
} from '@app/models/kyc-kyb/types';
import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';
import { PayrollResources } from '@app/modules/payroll/payroll.resources';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { getYear } from 'date-fns';
import { isNil } from 'lodash-es';
import { Client as PersonaClient } from 'persona';
import { InquiryError } from 'persona/dist/lib/interfaces';
import { BehaviorSubject, combineLatest, defer, from, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CountryService } from '../country.service';

/**
 * Service for interacting with Persona as part of the Know-Your-Client and Know-Your-Business verification flows
 */
@Injectable({
    providedIn: 'root',
})
export class KYCKYBService {
    /**
     * The Persona Account (KYC)
     */
    readonly personaAccount: Observable<PersonaAccount>;

    /**
     * The Persona Company (KYB)
     */
    readonly personaCompany: Observable<PersonaAccount>;

    /**
     * KYC Status
     */
    get accountStatus(): Observable<HumiInquiryStatus> {
        if (this.isKYCComplete) {
            return of('complete');
        }
        return this.personaAccount.pipe(map((personaAccount) => personaAccount.status));
    }

    /**
     * KYB Status
     */
    get companyStatus(): Observable<HumiInquiryStatus> {
        if (this.isKYBComplete) {
            return of('complete');
        }
        return this.personaCompany.pipe(map((personaCompany) => personaCompany.status));
    }

    /**
     * Gets the KYB Inquiry details including all child UBO inquiries
     */
    get companyInquiry(): Observable<PersonaInquiry | undefined> {
        return this.personaCompany.pipe(
            switchMap((personaCompany) => {
                // Need an inquiry ID to send to the API
                if (!personaCompany.inquiry?.inquiryId) {
                    return of(undefined);
                }
                return PersonaInquiry.param('inquiry', personaCompany.inquiry.inquiryId).show();
            })
        );
    }

    get isKYCComplete(): boolean {
        return !!this.authService.account.personaCompletedAt;
    }

    get isKYBComplete(): boolean {
        return !!this.authService.company.personaCompletedAt;
    }

    /**
     * The Persona Client can take some time to load the script. This allows us to show a spinner in the interim
     */
    get isLoading(): boolean {
        return this._isLoading;
    }

    private _personaAccount = new BehaviorSubject<PersonaAccount | undefined>(undefined);
    private _personaCompany = new BehaviorSubject<PersonaAccount | undefined>(undefined);
    private _primaryOffice = new ReplaySubject<Office>(1);
    /**
     * Defer and injector are used to ensure that we're only retrieving Countries when it's required.
     * The CountryService makes the API request the moment it's instantiated, by using defer and injector, it will only instantiate upon being subscribed to
     */
    private _countries = defer(() => this.injector.get(CountryService).allCountries);
    /**
     * We only need to retrieve the list of countries once. This flag allows us to know that the countries is already populated
     */
    private _hasCountries = false;
    private _personaClient?: PersonaClient;
    private _isLoading = false;

    constructor(
        private authService: AuthService,
        private injector: Injector
    ) {
        // Initializes both Observables (Note they won't be actually executed until subscribed to)
        this.personaAccount = this.getPersonaAccount('KYC');
        this.personaCompany = this.getPersonaAccount('KYB');
    }

    private get accountId(): number {
        return this.authService.accountId;
    }

    private get companyId(): number {
        return this.authService.company.id;
    }

    /**
     * Gets the KYC status of an employee OTHER than the currently logged in user.
     * Unlike the current user's status, this will never be cached
     */
    getAccountStatusForEmployee(employee: Employee): Observable<HumiInquiryStatus> {
        if (employee.account.personaCompletedAt) {
            return of('complete');
        }
        return from(this.getAccountModel('KYC', employee.account.id.toString())).pipe(
            map((personaAccount) => personaAccount.status)
        );
    }

    /**
     * Public function for launching the Persona iFrame
     * Will handle both new and existing sessions, as well as KYC or KYB
     */
    launchPersona(scope: PersonaScope, prefillKYBFields?: KYBPrefill): Observable<void> {
        this._isLoading = true;
        const personaAccount = scope === 'KYB' ? this.personaCompany : this.personaAccount;

        // Populate Country Codes (this is run only once KYB or KYC is launched)
        if (!this._hasCountries) {
            this._countries.pipe(take(1)).subscribe(() => (this._hasCountries = true));
        }

        return personaAccount.pipe(
            take(1), // PersonaAccount is occasionally refreshed - we only need the latest value from it to launch Persona
            switchMap(({ templateId, accountId, environmentId, inquiry, status }) => {
                // If an inquiry exists, create a session token to resume the inquiry
                if (inquiry?.inquiryId && inquiry?.status !== 'declined' && status === 'not_complete') {
                    return this.createSessionToken(inquiry.inquiryId);
                }

                // For KYB it is required that we provide signing-officer details prior to launching Persona
                // If it's not provided we throw an error that will trigger the dialog to collect the data
                if (scope === 'KYB' && isNil(prefillKYBFields)) {
                    this._isLoading = false;
                    throw new KYBSigningOfficerRequiredError();
                }

                // Otherwise create a new inquiry
                return of({ templateId, referenceId: accountId, environmentId });
            }),
            tap(() => {
                // Populate the office data for KYB that is used for pre-filling the form fields
                if (scope === 'KYB') {
                    const { company } = this.authService;
                    // Populate Office Data (this is run every time KYB is launched in case the data has changed)
                    Office.param('company', company.id)
                        .find(company.primaryAddressId)
                        // This is saved in a private variable to be consumed later
                        .then((office) => this._primaryOffice.next(office));
                }
            }),
            tap((personaAttributes) => this.openPersonaClient(personaAttributes, scope, prefillKYBFields)),
            map(() => void 0) // We don't need to return any data so just map to void
        );
    }

    /**
     * This function is run once per render of a view that shows the status of a user's KYC/KYB
     * The purpose is to check if they have a status that is pending action from a 3rd-party (ie. "completed" needs an admin to "approve")
     *
     * If the user's inquiry IS in one of these statuses then we clear the cached inquiry so that we can live retrieve it once more from the BE
     */
    checkForRefresh(scope: PersonaScope): void {
        (scope === 'KYB' ? this._personaCompany : this._personaAccount)
            .pipe(
                take(1),
                filter(
                    (personaAccount) =>
                        !!personaAccount?.inquiry?.status &&
                        INVALIDATE_CACHE_STATUSES.includes(personaAccount.inquiry.status)
                )
            )
            .subscribe(this.refreshPersona.bind(this, scope));
    }

    /**
     * Re-sends an invite to a user for the KYB Persona workflow
     */
    resendInvite({ inquiryId }: PersonaInquiry): Observable<void> {
        return from(new PersonaResendInvite({ inquiryId }).create()).pipe(map(() => void 0));
    }

    /**
     * Clear cached inquiries and clear out any data related to the Persona Client
     */
    private refreshPersona(scope: PersonaScope): void {
        if (scope === 'KYB') {
            this._personaCompany.next(undefined);
        }
        // Always refresh the Persona Account
        this._personaAccount.next(undefined);

        // Re-calculates the payroll documents step now that KYB status may have changed
        if (this.authService.company.selfServeQuickstart) {
            this.injector.get(OnboardingStatusService).refresh();
        }

        this._personaClient?.destroy();
        this._personaClient = undefined;
    }

    /**
     * Determines whether to return the Persona Account details from the BE or via a cached value in private BehaviorSubjects
     */
    private getPersonaAccount(scope: PersonaScope): Observable<PersonaAccount> {
        // Private cached values
        const scopedAccountCache = scope === 'KYB' ? this._personaCompany : this._personaAccount;

        return scopedAccountCache.pipe(
            switchMap((personaAccount) => {
                // If the account has already been retrieved from the BE just return the cached value
                if (personaAccount) {
                    return of(personaAccount);
                }

                // If there is not an existing cached value, retrieve from the BE
                return from(this.getAccountModel(scope)).pipe(
                    tap((retrievedPersonaAccount) => scopedAccountCache.next(retrievedPersonaAccount)) // Cache the return value once retrieved
                );
            })
        );
    }

    /**
     * Model functions for KYC and KYB to retrieve inquiries from the BE
     */
    private getAccountModel = (scope: PersonaScope, entityId?: string): Promise<PersonaAccount> =>
        scope === 'KYB'
            ? PersonaAccount.param('entityType', 'company').param('entityId', this.companyId).show() // Always only check the user's own company
            : PersonaAccount.param('entityType', 'account')
                  .param('entityId', entityId ?? this.accountId) // Can check the current user or an employee they have access to view
                  .show();

    /**
     * When an inquiry is in progress we have reference to the inquiry ID.
     * Sending this to the BE allows us to generate a session token which will resume the user's inquiry process when we launch the client.
     */
    private createSessionToken(inquiryId: string): Observable<ResumePersonaSessionAttributes> {
        return from(new PersonaInquiryToken({ inquiryId }).create()).pipe(
            map((personaInquiryToken) => personaInquiryToken)
        );
    }

    /**
     * Gets the location data used to pre-fill fields in KYB and KYC flows
     */
    private getLocationData(scope: PersonaScope): Observable<{ countryCode: string[2]; office?: Office }> {
        if (scope === 'KYC') {
            return this._countries.pipe(
                take(1),
                map((countries) => ({
                    // Converts employee's address country name to country code (Canada -> CA)
                    countryCode:
                        countries
                            .find((country) => country.name === this.authService.employee.address?.country)
                            ?.code.toUpperCase() ?? '',
                }))
            );
        }
        return combineLatest([this._primaryOffice, this._countries]).pipe(
            take(1),
            map(([office, countries]) => ({
                office,
                // Converts primary office's country name to country code (Canada -> CA)
                countryCode: countries.find((country) => country.name === office.country)?.code.toUpperCase() ?? '',
            }))
        );
    }

    /**
     * Gets data used to pre-fill fields in KYB and KYC flows
     */
    private getFields(
        scope: PersonaScope,
        location: { countryCode: string[2]; office?: Office },
        prefillKYBFields?: KYBPrefill
    ): KYCPrefill | KYBPrefill {
        const countryCode = location?.countryCode;

        const { company, account, employee } = this.authService;
        if (scope === 'KYC') {
            // Currently we only pre-fill country code, first name, last name and email for KYC
            return {
                selected_country_code: countryCode ?? '',
                email_address: account.email,
                name_first: account.legalFirstName,
                name_last: account.legalLastName,
            };
        }

        const office = location?.office;

        return {
            ...prefillKYBFields,
            business_name: company.name,
            business_tax_identification_number: company.craBusinessNumber,
            incorporation_year: getYear(company.incorporationDate),
            business_physical_address_street_1: office?.addressLine1 ?? '',
            business_physical_address_street_2: office?.addressLine2 ?? '',
            business_physical_address_city: office?.city ?? '',
            business_physical_address_subdivision: office?.province ?? '',
            business_physical_address_postal_code: office?.postalCode ?? '',
            business_physical_address_country_code: countryCode ?? '',
            initiator_employee_id: employee.id,
            signing_officer_api_endpoint: environment.api + PayrollResources.KYBUpdateSigningOfficer,
        };
    }

    /**
     * Sets the private _personaClient variable and attempts to open the iFrame for the user.
     * This handles both new sessions and resumed sessions.
     */
    private openPersonaClient(
        {
            templateId,
            referenceId,
            environmentId,
            inquiryId,
            sessionToken,
        }: Partial<NewPersonaSessionAttributes & ResumePersonaSessionAttributes>,
        scope: PersonaScope,
        prefillKYBFields?: KYBPrefill
    ): void {
        this.getLocationData(scope).subscribe(
            (locationData) =>
                (this._personaClient = new PersonaClient({
                    templateId,
                    referenceId,
                    environmentId,
                    inquiryId,
                    sessionToken,
                    fields: this.getFields(scope, locationData, prefillKYBFields),
                    onReady: this.onPersonaReady.bind(this),
                    onComplete: this.onPersonaComplete.bind(this, scope),
                    onCancel: this.onPersonaCancel.bind(this, scope),
                    onError: (args): void => this.onPersonaError(args, scope),
                }))
        );
    }

    // Callbacks for the various events that can be produced from the Persona Client

    private onPersonaReady(): void {
        this._personaClient?.open();
        this._isLoading = false;
    }

    private onPersonaComplete(scope: PersonaScope): void {
        this._isLoading = false;
        this.refreshPersona(scope);
    }

    private onPersonaCancel(scope: PersonaScope): void {
        this._isLoading = false;
        this.refreshPersona(scope);
    }

    private onPersonaError(args: InquiryError, scope: PersonaScope): void {
        Sentry.captureException(args, { tags: { persona: true } });
        this._isLoading = false;
        this.refreshPersona(scope);
    }
}

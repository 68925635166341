import { AuthService } from '@app/services';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { Paginator, SortDirection } from '@app/classes';
import { Injectable } from '@angular/core';
import { DefaultGroupTypeColumn } from '@settings/services/tables/field-groups-table/columns/default-group-type.column';
import { LocationColumn, NumberOfFieldsColumn } from '@settings/services/tables/field-groups-table/columns';
import { Table } from '@table/classes/table.class';
import { MenuColumnItem } from '@table/interfaces/menu-column-item.interface';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

@Injectable()
export class DefaultFieldGroupsIndexTable extends Table {
    showMenuColumnOptions = true;
    sortProperty = 'id';
    sortDirection = SortDirection.ASC;
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(dataFieldGroup: DataFieldGroup): (string | number)[] {
        return ['/settings', 'custom-fields', 'field-groups', dataFieldGroup.id, 'edit'];
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption];
    }

    setColumns(): void {
        this.addColumns(new DefaultGroupTypeColumn(), new LocationColumn(), new NumberOfFieldsColumn());
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            DataFieldGroup.param('company', this.auth.company.id).where('onlyDefaultGroups', true)
        );
    }
}

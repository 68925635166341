import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import moment from 'moment';
import { ModelMixin } from '../core/base-generic.model';
import { RL1Summary } from './rl1-summary.model';
import { TaxReturnContactInfo, TaxReturnSummary } from './tax-return-summary.abstract.model';

type T4SummaryState = 'open' | 'issued';
type T619State = 'new' | 'open' | 'processing' | 'pending' | 'complete' | 'rejected' | 'error';

export class T4Summary extends ModelMixin<T4Summary>() implements TaxReturnSummary {
    protected static _resource = 'payroll/companies/:company/t4Summaries';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    protected static _serializeAttributes = [
        'name',
        'date',
        'totEmptIncamt',
        'totEmpeCppAmt',
        'totEmpeCppeAmt',
        'totEmpeEipAmt',
        'totRppCntrbAmt',
        'totItxDdctAmt',
        'totPadjAmt',
        'totEmprCppAmt',
        'totEmprCppeAmt',
        'totEmprEipAmt',
        'craFormT619Id',
        'slpCnt',
        'rptTcd',
        'encryptedPprtr1Sin',
        'encryptedPprtr1SinSalt',
        'encryptedPprtr1SinIv',
        'encryptedPprtr2Sin',
        'encryptedPprtr2SinSalt',
        'encryptedPprtr2SinIv',
        'bulkEmailSentAt',
        'defaultContact',
        'year',
        'taxReturnId',
    ];

    get state(): T4SummaryState {
        return this._attributes['state'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get rptTcd(): string {
        return this._attributes['rptTcd'];
    }

    get date(): string {
        return this._attributes['date'];
    }

    get createdAt(): string {
        return this._attributes['createdAt'];
    }

    get totEmptIncamt(): number {
        return this._attributes['totEmptIncamt'];
    }

    get totEmpeCppAmt(): number {
        return this._attributes['totEmpeCppAmt'];
    }

    get totEmpeCppeAmt(): number {
        return this._attributes['totEmpeCppeAmt'];
    }

    get totEmpeEipAmt(): number {
        return this._attributes['totEmpeEipAmt'];
    }

    get totRppCntrbAmt(): number {
        return this._attributes['totRppCntrbAmt'];
    }

    get totItxDdctAmt(): number {
        return this._attributes['totItxDdctAmt'];
    }

    get totPadjAmt(): number {
        return this._attributes['totPadjAmt'];
    }

    get totEmprCppAmt(): number {
        return this._attributes['totEmprCppAmt'];
    }

    get totEmprCppeAmt(): number {
        return this._attributes['totEmprCppeAmt'];
    }

    get totEmprEipAmt(): number {
        return this._attributes['totEmprEipAmt'];
    }

    get craFormT619Id(): string {
        return this._attributes['craFormT619Id'];
    }

    get slpCnt(): string {
        return this._attributes['slpCnt'];
    }

    get encryptedPprtr1Sin(): any {
        return this._attributes['encryptedPprtr1Sin'];
    }

    get encryptedPprtr1SinSalt(): any {
        return this._attributes['encryptedPprtr1SinSalt'];
    }

    get encryptedPprtr1SinIv(): any {
        return this._attributes['encryptedPprtr1SinIv'];
    }

    get encryptedPprtr2Sin(): any {
        return this._attributes['encryptedPprtr2Sin'];
    }

    get encryptedPprtr2SinSalt(): any {
        return this._attributes['encryptedPprtr2SinSalt'];
    }

    get encryptedPprtr2SinIv(): any {
        return this._attributes['encryptedPprtr2SinIv'];
    }

    get bulkEmailSentAt(): boolean {
        return this._attributes['bulkEmailSentAt'];
    }

    get year(): number {
        if (this.date === undefined) {
            return this._attributes['year'];
        }

        return moment(this.date).year();
    }

    get submittedAt(): string | null {
        return this._attributes['submittedAt'];
    }

    get completedAt(): string | null {
        return this._attributes['completedAt'];
    }
    set completedAt(val: string | null) {
        this._attributes['completedAt'] = val;
    }

    get t619State(): T619State {
        return this._attributes['t619State'];
    }
    set t619State(val: T619State) {
        this._attributes['t619State'] = val;
    }

    get manualSelectedAt(): string {
        return this._attributes['manualSelectedAt'];
    }

    get canSubmit(): boolean {
        return this._attributes['canSubmit'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set date(val: string) {
        this._attributes['date'] = val;
    }

    set totEmptIncamt(val: number) {
        this._attributes['totEmptIncamt'] = val;
    }

    set totEmpeCppAmt(val: number) {
        this._attributes['totEmpeCppAmt'] = val;
    }

    set totEmpeCppeAmt(val: number) {
        this._attributes['totEmpeCppeAmt'] = val;
    }

    set totEmpeEipAmt(val: number) {
        this._attributes['totEmpeEipAmt'] = val;
    }

    set totRppCntrbAmt(val: number) {
        this._attributes['totRppCntrbAmt'] = val;
    }

    set totItxDdctAmt(val: number) {
        this._attributes['totItxDdctAmt'] = val;
    }

    set totPadjAmt(val: number) {
        this._attributes['totPadjAmt'] = val;
    }

    set totEmprCppAmt(val: number) {
        this._attributes['totEmprCppAmt'] = val;
    }

    set totEmprCppeAmt(val: number) {
        this._attributes['totEmprCppeAmt'] = val;
    }

    set totEmprEipAmt(val: number) {
        this._attributes['totEmprEipAmt'] = val;
    }

    set craFormT619Id(val: string) {
        this._attributes['craFormT619Id'] = val;
    }

    set slpCnt(val: string) {
        this._attributes['slpCnt'] = val;
    }

    set encryptedPprtr1Sin(val: any) {
        this._attributes['encryptedPprtr1Sin'] = val;
    }

    set encryptedPprtr1SinSalt(val: any) {
        this._attributes['encryptedPprtr1SinSalt'] = val = val;
    }

    set encryptedPprtr1SinIv(val: any) {
        this._attributes['encryptedPprtr1SinIv'] = val;
    }

    set encryptedPprtr2Sin(val: any) {
        this._attributes['encryptedPprtr2Sin'] = val;
    }

    set encryptedPprtr2SinSalt(val: any) {
        this._attributes['encryptedPprtr2SinSalt'] = val;
    }

    set encryptedPprtr2SinIv(val: any) {
        this._attributes['encryptedPprtr2SinIv'] = val;
    }

    set bulkEmailSentAt(val: boolean) {
        this._attributes['bulkEmailSentAt'] = val;
    }

    set year(val: number) {
        this._attributes['year'] = val;
    }

    set defaultContact(contactInfo: TaxReturnContactInfo) {
        this._attributes['defaultContact'] = contactInfo;
    }

    isOriginal(): boolean {
        return this.rptTcd === 'O';
    }

    get isAmendment(): boolean {
        return this.rptTcd === 'A';
    }

    get isOpen(): boolean {
        return this.state === 'open' || !this.submittedAt || ['new', 'open', 'error'].includes(this.t619State);
    }

    /**
     * @deprecated
     */
    isIssued(): boolean {
        return this.isSubmitted;
    }

    get isSubmitted(): boolean {
        return (
            this.state === 'issued' ||
            !!this.submittedAt ||
            ['pending', 'processing', 'complete', 'rejected'].includes(this.t619State)
        );
    }

    get isErrored(): boolean {
        return this.t619State === 'error';
    }

    isRL1Summary(): this is RL1Summary {
        return false;
    }

    isT4Summary(): this is T4Summary {
        return true;
    }

    get stateBadge(): BadgeDisplay | undefined {
        if (!this.t619State) return undefined;

        const label = `payroll.taxReturns.t4SummaryStatuses.${this.t619State}`;

        switch (this.t619State) {
            case 'open':
                return { label, type: 'informative' };
            case 'processing':
            case 'pending':
            case 'complete':
                return { label, type: 'success' };
            case 'error':
            case 'rejected':
                return { label, type: 'danger' };
            default:
                return undefined;
        }
    }

    get isComplete(): boolean {
        return this.t619State === 'complete';
    }

    get taxReturnId(): string | null {
        return this._attributes['taxReturnId'];
    }

    /**
     * Whether individual T4 e-mails can be sent to employees
     */
    get canSendEmails(): boolean {
        // T4 E-mails can be sent as soon as they've been submitted, even if they are in a processing state
        return this.isSubmitted;
    }
}

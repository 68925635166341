<mat-accordion>
    <mat-expansion-panel *ngFor="let entity of entities; first as isFirst" [expanded]="isFirst">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ entity.label }}

                <!--count-->
                <ng-container>
                    <span *ngIf="getCountOfCheckedValues(entity) > 0" class="counter">
                        {{ getCountOfCheckedValues(entity) }}
                    </span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <!--Select all-->
        <mat-checkbox
            *ngIf="entity.showSelectAll"
            [checked]="isSelectAllChecked(entity)"
            (change)="setCheckedProperty($event, entity)"
        >
            <span class="text-color">Select All</span>
        </mat-checkbox>

        <!--Other options-->
        <div class="flex-container content">
            <div *ngFor="let option of entity.options">
                <mat-checkbox class="column" [(ngModel)]="option.checked" [disabled]="option.disabled">
                    <!-- TODO: {{ option.value | translate }} Use option -> 'value' translationKey coming from backend to display when it is updated-->
                    <span class="text-color">{{ option.label }}</span>
                </mat-checkbox>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

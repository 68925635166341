import { Benefit } from '@app/models/payroll/benefit.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class GlCodePayableColumn extends Column {
    title = 'payroll.benefits.glCodePayable';
    property = 'glCodePayable';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(benefit: Benefit): string {
        if (benefit.benefitTypeDisplayName === 'Taxable Benefit (Non-Cash)') {
            return 'N/A';
        }
        return benefit.glCodePayable ?? '-';
    }
}

<mat-form-field class="w-100">
    <mat-label *ngIf="label.length">{{ label }}</mat-label>
    <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="placeholder"
        [name]="name"
        [formControl]="date"
        (dateChange)="onDatePickerChange($event.value)"
    />
    <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        aria-label="{{ label }} {{ 'screen-reader-only.openCalendar' | translate }}"
    ></mat-datepicker-toggle>
    <mat-datepicker
        #picker
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, picker)"
        panelClass="month-picker"
    >
    </mat-datepicker>
</mat-form-field>

import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { addYears, subYears } from 'date-fns';
import moment from 'moment';

import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ui-date-picker',
    templateUrl: './date-picker.template.html',
    styles: [
        `
            :host mat-form-field {
                width: 100%;
            }
        `,
    ],
    providers: [
        { provide: MatFormFieldControl, useExisting: DatePickerComponent },
        { provide: MatFormField, useClass: MatFormField },
    ],
})
export class DatePickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit {
    static nextId = 0;

    @Input() required = false;
    @Input() disabled = false;
    @Input() hideRequiredMarker = false;
    @Input() label = '';
    @Input() hint?: string;
    @Input() labelVisible = true;

    /**
     * Open the datepicker as soon as the input is focused
     */
    @Input() openOnFocus = false;

    /**
     * When the maxDate or minDate is changed, we need to tell Angular to update the validity of the NgControl.
     * We do this in a setTimeout so that it happens after a change detection cycle.
     */

    @Input() set maxDate(maxDate: Date) {
        this._maxDate = maxDate;
        setTimeout(() => this.ngControl.control?.updateValueAndValidity());
    }
    @Input() set minDate(minDate: Date) {
        this._minDate = minDate;
        setTimeout(() => this.ngControl.control?.updateValueAndValidity());
    }

    get maxDate(): Date {
        return this._maxDate;
    }
    get minDate(): Date {
        return this._minDate;
    }

    @Input() id = `datepicker-${(DatePickerComponent.nextId += 1)}`;
    @Input() placeholder = '';

    onTouched?: (event: Event) => void;
    onChange?: (date: Moment) => void;
    inputControl?: FormControl<Moment | null>;
    lastInput = null;

    private _destroyed: Subject<null> = new Subject();
    private _maxDate: Date = addYears(new Date(), 200);
    private _minDate: Date = subYears(new Date(), 200);

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public matFormField: MatFormField
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        // A safeguard in place in case the user presses submit while still editing the field so that their value is still captured
        this._parentForm?.ngSubmit.pipe(takeUntil(this._destroyed)).subscribe(() => {
            // If the lastInput is null then they either didn't change the field, or set the date using the date picker
            if (this.lastInput !== null) {
                const date = moment(this.lastInput);
                if (date.isValid()) {
                    this.ngControl.control?.setValue(moment(this.lastInput));
                } else {
                    this.ngControl.control?.setValue(null);
                }
            }
        });
    }

    ngOnInit(): void {
        this.inputControl = new FormControl({ value: null, disabled: this.disabled });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled'] && this.inputControl) {
            if (this.disabled) {
                this.inputControl.disable();
                return;
            }

            this.inputControl.enable();
        }
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    onDatePickerChange(date: Moment): void {
        this.onChange?.(date);
        this.ngControl.control?.setValue(moment(date));
        // Reset the last input since the field has been overwritten
        this.lastInput = null;
    }

    // binds the validators of the form control with the material form field
    ngAfterViewInit(): void {
        this.ngControl?.control?.setValidators(this.inputControl.validator.bind(this, this.inputControl));
    }

    // Used by value accessor to set callbacks for ngModel
    registerOnChange(fn: (date: Moment) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: (event: Event) => void): void {
        this.onTouched = fn;
    }

    getDateLimit(): Date {
        return this.inputControl?.hasError('matDatepickerMin') ? this.minDate : this.maxDate;
    }

    isToday(date: Date): boolean {
        return moment(date).isSame(new Date(), 'day');
    }

    // Only used if the form field's value is set programmatically
    writeValue(value: Moment): void {
        this.inputControl?.setValue(value ? moment(value) : null);
    }
}

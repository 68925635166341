import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Country } from '@app/models/common/country.model';
import { CountryService } from '@app/services/country.service';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import { takeWhile } from 'rxjs/operators';

/**
 * Sometimes the API returns only the English country name instead of the country code. This instead allows us to translate when necessary
 */
@Pipe({ name: 'i18nCountry', standalone: true, pure: false })
export class LocalizedCountryPipe implements PipeTransform {
    value: string = '';
    lastCountry: string | undefined | null;
    countries: Country[] = [];

    constructor(
        private translateService: TranslateService,
        private countryService: CountryService,
        private _ref: ChangeDetectorRef
    ) {
        this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.lastCountry = null;
            this._ref.markForCheck();
        });
        this.countryService.allCountries
            .pipe(takeWhile((countries) => !countries?.length, true))
            .subscribe((countries) => {
                this.countries = countries;
            });
    }

    transform(country: string | null | undefined): string | null {
        if (!country) {
            return null;
        }

        // Still waiting for countries to load
        if (!this.countries.length) {
            return '';
        }

        // Nothing has changed
        if (isEqual(country, this.lastCountry)) {
            return this.value;
        }

        this.lastCountry = country;

        this.value =
            this.countries.find(({ name }) => name === country)?.[
                this.translateService.currentLang === 'fr_CA' ? 'frenchName' : 'name'
            ] ?? country;

        return this.value;
    }
}

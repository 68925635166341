import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { formatDate } from '@app/functions/date-format';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'date-fns';
import { isEqual } from 'lodash-es';
import { isMoment, Moment } from 'moment';
import { DateFormat } from './types';

@Pipe({ name: 'i18nDate', standalone: true, pure: false })
export class LocalizedDatePipe implements PipeTransform {
    value: string = '';
    lastDate: Moment | Date | string | null | undefined;
    lastFormat: DateFormat | undefined;

    constructor(
        private translateService: TranslateService,
        private _ref?: ChangeDetectorRef
    ) {
        this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.lastDate = null;
            this._ref?.markForCheck();
        });
    }

    transform(date: Moment | Date | string | null | undefined, format: DateFormat): string | null {
        if (!date) {
            return null;
        }

        // Nothing has changed
        if (isEqual(date, this.lastDate) && isEqual(format, this.lastFormat)) {
            return this.value;
        }

        this.lastDate = date;
        this.lastFormat = format;

        // allow a placeholder - without messing with date parsing.
        if (date === '-') {
            return '-';
        }

        if (isMoment(date)) {
            date = date.toDate();
        }

        this.value = formatDate(parse(date), format, this.translateService.currentLang);
        return this.value;
    }
}

/**
 * Error thrown to trigger the signing-officer model launch before opening Persona
 */
export class KYBSigningOfficerRequiredError extends Error {
    // This is not client facing, only used internally
    message = 'Signing officer details must be provided before launching Persona';
}

export const isKYBSigningOfficerRequiredError = (err: unknown): err is KYBSigningOfficerRequiredError => {
    return err instanceof KYBSigningOfficerRequiredError;
};

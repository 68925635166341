import { Component, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChecklistAccordionGroup } from '@app/interfaces';
@Component({
    selector: 'app-checklist-accordion',
    templateUrl: './checklist-accordion.template.html',
    styleUrls: ['./checklist-accordion.style.scss'],
})
export class ChecklistAccordionComponent {
    @Input() entities: ChecklistAccordionGroup[];

    setCheckedProperty(event: MatCheckboxChange, entity: ChecklistAccordionGroup): void {
        entity.options.forEach((option) => (option.checked = event.checked));
    }

    isSelectAllChecked(entity: ChecklistAccordionGroup): boolean {
        return !entity.options.some((option) => !option.checked);
    }

    getCountOfCheckedValues(entity: ChecklistAccordionGroup): number {
        return entity.options.filter((option) => option.checked).length;
    }
}

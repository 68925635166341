import { Model } from '@models/core/base.model';

import { SimpleEntityActions } from '@app/classes';
import { NotificationData } from '@app/interfaces';
import { NotificationActionStatusType } from '@app/modules/dashboard/enums/notification-action-status-types.enum';
import { Translatable } from '@app/types/translatable.type';
import { NotificationCategory } from 'app/models/common/notification-category.model';

export class Notification extends Model {
    static permission = new SimpleEntityActions('notification');

    protected static _resource = 'notificationManagement/notifications';
    protected static _version = 'v2';
    protected static _datetimes = ['readAt', 'archivedAt'];
    protected static _serializeAttributes = [
        'notifiableId',
        'notifiableType',
        'notificationType',
        'data',
        'readAt',
        'archivedAt',
        'isRead',
    ];

    // models read state cached, avoids large numbers of constant method calls n dashboard (has performance impact)
    public isReadStatus = false;

    constructor(res?: object | object[]) {
        super(res);
        this.isReadStatus = !!this.readAt;
    }

    get category(): NotificationCategory {
        return this.hasOne(NotificationCategory, 'category');
    }

    get categoryName(): Translatable {
        return this.category ? this.category.displayName : NotificationCategory.getGeneralDisplayName();
    }

    get notificationType(): string {
        return this._attributes['notificationType'];
    }

    get data(): NotificationData {
        return this._attributes['data'];
    }

    set data(val: NotificationData) {
        this._attributes['data'] = val;
    }

    get createdAt(): string {
        return this._attributes['createdAt'];
    }

    get readAt(): any {
        return this._attributes['readAt'];
    }

    set readAt(val: any) {
        this._attributes['readAt'] = val;
        this.isReadStatus = !!val;
    }

    get archivedAt(): Date {
        return this._attributes['archivedAt'];
    }

    set archivedAt(val: Date) {
        this._attributes['archivedAt'] = val;
    }

    get isRead(): boolean {
        return !!this.readAt;
    }

    get link(): string | null {
        return this._attributes['link'] || this.data?.link?.url;
    }

    get status(): NotificationActionStatusType | null {
        return this._attributes['status'];
    }

    toggleRead(isLegacy?: boolean): Promise<any> {
        this.readAt = !this.readAt ? new Date() : null;

        // TODO: SQA-2387 Remove this once NotificationEnhancement is launched
        if (isLegacy) {
            this.archivedAt = this.readAt;
        }

        return this.save();
    }

    async archive(): Promise<void> {
        this.archivedAt = new Date();

        await this.save();
    }

    async unarchive(): Promise<void> {
        this.archivedAt = null;

        await this.save();
    }

    hasLink(): boolean {
        return Boolean(this.link);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

export function reduceFilterGroupToSelectedOptions<T = string | number | boolean>(
    group: FilterGroup<T>
): Array<FilterOption<T>> {
    return group.options.filter((option: FilterOption<T>) => option.selected);
}

export function countSelectedFilters(groups: Array<FilterGroup>): number {
    const selectedCounts = groups.map((filterOption) => reduceFilterGroupToSelectedOptions(filterOption).length);
    return selectedCounts.reduce((a, b) => a + b, 0);
}

export interface FilterOption<T = string | number | boolean> {
    label: string;
    value: T;
    selected: boolean;
}

export interface FilterGroup<T = string | number | boolean> {
    label: string;
    options: Array<FilterOption<T>>;
}

@Component({
    selector: 'app-filter',
    templateUrl: './filter.template.html',
    styleUrls: ['./filter.style.scss'],
})
export class FilterComponent {
    @Input() groups: Array<FilterGroup>;
    @Input() useRaisedButton = true;
    @Output() filter: EventEmitter<null> = new EventEmitter<null>();

    activeFilterCount = 0;

    select($event: any, option: FilterOption): void {
        // stop event, otherwise select box will close
        $event.stopPropagation();
        option.selected = !option?.selected;
        this.activeFilterCount = countSelectedFilters(this.groups);
        this.filter.emit();
    }

    clear($event: any): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.groups.forEach((filter) => filter.options.forEach((option) => (option.selected = false)));
        this.activeFilterCount = 0;
        this.filter.emit();
    }
}

import { Model } from '../core/base.model';

export class State extends Model {
    protected static _resource = 'core/data/countries/:country/states';

    protected static _version = 'v1';

    get name(): string {
        return this._attributes['name'] || null;
    }

    get code(): string {
        return this._attributes['code'] || null;
    }

    get frenchName(): string | null {
        return this._attributes['frenchName'] || null;
    }
}

import { Directive, effect, TemplateRef, ViewContainerRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Directive({
    standalone: true,
    selector: '[hideFrench]',
})
/**
 * Hides the element if the user's current language is French. Mostly used for hiding images
 */
export class HideIfFrenchDirective {
    currentLang = toSignal(this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)));

    hideElement = effect(() => {
        if (this.currentLang() === 'fr_CA') {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    });

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private translateService: TranslateService
    ) {}
}

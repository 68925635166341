import { AnimationEvent } from '@angular/animations';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FadeAnimationOpenClosed } from '@app/animations/fade.animation';
import { css } from 'emotion';

export const overlayAnimationTime = 300;

@Component({
    selector: 'ui-overlay',
    animations: [FadeAnimationOpenClosed(overlayAnimationTime)],
    standalone: true,
    template: `
        <div
            (keydown)="handleKeyDown($event)"
            class="{{ classString }} {{ scrollable ? 'scrollable' : '' }}"
            #overlay
            (click)="overlayClick($event)"
            [@fading]="open ? 'open' : 'closed'"
            (@fading.done)="onAnimationEnd($event)"
        >
            @if (showingContent) {
                <ng-content></ng-content>
            }
        </div>
    `,
})
export class OverlayComponent implements OnChanges, OnInit {
    showingContent = false;
    classString: string = null;

    @Input() open = false;
    @Input() scrollable = false;
    @Input() centerContent = true;
    /**
     * Used to account for things like fixed topbars
     */
    @Input() topOffset = 0;
    @Output() onClick = new EventEmitter();

    /** a reference to the overlay element */
    @ViewChild('overlay') overlay: ElementRef<HTMLDivElement>;

    _styles = '';

    constructor() {
        this.setStyles();
    }

    ngOnInit(): void {
        this.generateClassString();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('topOffset' in changes) {
            this.setStyles();
        }
        this.generateClassString();
    }

    generateClassString(): void {
        this.setStyles();
        this.classString = `${this._styles} ${this.centerContent ? 'align-items-center flex justify-center' : ''}`;
    }

    /** handle click on overlay */
    overlayClick(event: MouseEvent): void {
        if (event.target !== this.overlay.nativeElement) return;
        event.stopPropagation();
        this.onClick.emit();
    }

    handleKeyDown(evt: KeyboardEvent): void {
        switch (evt.key) {
            case 'Escape': {
                evt.stopImmediatePropagation();
                this.onClick.emit();
                break;
            }
        }
    }

    onAnimationEnd({ toState }: AnimationEvent): void {
        this.showingContent = toState === 'open';
    }

    private setStyles(): void {
        /**
         * Top value accounts for custom fixed header within dialogs. Mostly used in
         * the PDF preview dialog.
         */
        this._styles = css`
            position: fixed;
            top: ${this.topOffset}px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2000;
            background-color: var(--dialog-overlay-background-color);
            transition: top 0.2s;

            &:before {
                background-color: var(--dialog-overlay-background-color);
                content: ' ';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                height: ${this.topOffset}px;
                transition: height 0.2s;
            }

            &.scrollable {
                overflow-y: auto;
            }
        `;
    }
}

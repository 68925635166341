import { DecimalPipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Employee } from '@app/models/employee/employee.model';
import { TimeOffApprovalFlowStep } from '@app/models/time-off-v3/time-off-approval-flow-step.model';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { TimeOffTransaction } from '@app/models/time-off-v3/time-off-transaction.model';
import { AuthService } from '@app/services';
import { Translatable } from '@app/types/translatable.type';
import { Meta } from '@interfaces/json-api-resource.interface';
import { TimeOffSeniorityOverride } from '@models/time-off-v3/time-off-seniority-override.model';
import { getTime } from '@time-off-v3/functions/time-unit-converter';
import { TimeOffBalance, TypeAndPolicyWithBalance } from '@time-off-v3/interfaces/time-off-balance.interface';
import {
    AccrualFrequencies,
    DECIMAL_PLACES,
    DECIMAL_PLACES_FOR_PIPE,
    TimeOffDateFormatDashes,
    TimeOffDateFormatShortMonthDayYear,
} from '@time-off-v3/meta/time-off-meta';
import { TimeOffService } from '@time-off-v3/services';
import moment from 'moment';

@Component({
    selector: 'app-time-off-type-details',
    templateUrl: 'time-off-type-details.template.html',
    styleUrls: ['time-off-type-details.style.scss'],
})
export class TimeOffTypeDetails {
    @ViewChild('matExpansionPanel') matExpansionPanel: MatExpansionPanel;
    @Input() employee: Employee;

    futureTimeOffRequestBalance: number;
    futurePendingTimeOffRequestBalance: number;
    currentYearTimeOffTransactionBalance: number;
    timeOffApprovalSteps: TimeOffApprovalFlowStep[] = [];
    timeOffPolicy: TimeOffPolicy;
    timeOffTypeAndPolicyWithBalance: TypeAndPolicyWithBalance;
    decimalPlacesForPipe: string = DECIMAL_PLACES_FOR_PIPE;
    year = moment().year();
    todaysBalance: number;

    accrualFrequencies = AccrualFrequencies;
    displayExpiringTime = false;
    currentCarryoverBalance: number;
    carryoverBalanceExpiresOn: string;

    constructor(
        public auth: AuthService,
        private timeOffService: TimeOffService,
        private decimalPipe: DecimalPipe
    ) {}

    async loadData(_timeOffTypeAndPolicyWithBalance: TypeAndPolicyWithBalance): Promise<void> {
        this.timeOffTypeAndPolicyWithBalance = _timeOffTypeAndPolicyWithBalance;
        /**
         * TODO: Temp solution for preventing the race condition. The proper way to do this is to move this method to the modal component
         *       and use `beforeShow` hook to load the data and pass it down.
         */
        setTimeout(async () => {
            this.displayExpiringTime = false;

            this.timeOffPolicy = this.employee.allAssignedTimeOffPolicies.find(
                (timeOffPolicy: TimeOffPolicy) =>
                    this.timeOffTypeAndPolicyWithBalance.timeOffType.id === timeOffPolicy.timeOffTypeId &&
                    this.timeOffTypeAndPolicyWithBalance.timeOffPolicy.id === timeOffPolicy.id
            );
            // Don't calculate anything below because this is a future time off policy assignment.
            if (this.timeOffPolicy?.isFuture) {
                return;
            }

            this.carryoverBalanceExpiresOn = moment(
                this.timeOffPolicy.carriesOverOnHireDate ? this.employee.hiredAt : this.timeOffPolicy.carryoverDate,
                TimeOffDateFormatDashes
            )
                .year(+moment().format('YYYY'))
                .subtract(1, 'days') // carryover balance expires day before.
                .add(this.timeOffPolicy.carryoverExpireAfter, 'days')
                .format(TimeOffDateFormatShortMonthDayYear);

            const isHourlyBasis = this.timeOffTypeAndPolicyWithBalance.timeOffType.displayInHours;

            const carryoverDate =
                this.timeOffPolicy.carryoverDate &&
                moment(this.timeOffPolicy.carryoverDate, TimeOffDateFormatDashes)
                    .year(+moment().format('YYYY'))
                    .format(TimeOffDateFormatDashes);

            const endOfExpiringPeriod =
                this.timeOffPolicy.carryoverDate &&
                moment(this.timeOffPolicy.carryoverDate, TimeOffDateFormatDashes)
                    .year(+moment().format('YYYY'))
                    .add(this.timeOffPolicy.carryoverExpireAfter, 'days');

            if (
                this.timeOffPolicy.carryoverDate &&
                moment().isAfter(carryoverDate) &&
                moment().isBefore(endOfExpiringPeriod)
            ) {
                const expiringBalance = await this.timeOffService.getExpiringBalance(this.employee.id, {
                    'filters[timeOffTypeId]': this.timeOffTypeAndPolicyWithBalance.timeOffType.id,
                    'filters[asOfDate]': moment().format(TimeOffDateFormatDashes),
                });

                this.currentCarryoverBalance = isHourlyBasis
                    ? expiringBalance.expiringHoursBalance
                    : expiringBalance.expiringBalance;

                this.displayExpiringTime = this.currentCarryoverBalance > 0;
            }

            this.flagActiveSeniorityOverride();

            const timeOffBalance = await this.timeOffService.getBalance(this.employee.id, {
                'filters[timeOffTypeId]': this.timeOffPolicy.timeOffType.id,
                'filters[processedAt][to]': moment(),
            });

            this.currentYearTimeOffTransactionBalance = isHourlyBasis
                ? timeOffBalance.yearEndHoursUsed
                : timeOffBalance.yearEndUsed;

            this.futureTimeOffRequestBalance = isHourlyBasis
                ? timeOffBalance.futureHoursApproved
                : timeOffBalance.futureDaysApproved;

            this.futurePendingTimeOffRequestBalance = isHourlyBasis
                ? timeOffBalance.futureHoursPending
                : timeOffBalance.futureDaysPending;

            this.todaysBalance = this.timeOffTypeAndPolicyWithBalance?.timeOffType.displayInHours
                ? timeOffBalance.balanceHours
                : timeOffBalance.balance;
        });
    }

    getCarryoverBalance(
        beforeCarryoverDate: string,
        timeOffTypeAndPolicyWithBalance: TypeAndPolicyWithBalance
    ): Promise<TimeOffBalance> {
        return this.timeOffService.getBalance(this.employee.id, {
            'filters[timeOffTypeId]': timeOffTypeAndPolicyWithBalance.timeOffType.id,
            'filters[processedAt][to]': beforeCarryoverDate,
        });
    }

    getCarryoverTime(
        beforeCarryoverDate: string,
        timeOffTypeAndPolicyWithBalance: TypeAndPolicyWithBalance
    ): Promise<[TimeOffTransaction[], Meta]> {
        return TimeOffTransaction.where('processedAt', {
            from: beforeCarryoverDate,
            to: moment(this.timeOffPolicy.carryoverDate)
                .add(this.timeOffPolicy.carryoverExpireAfter, 'days')
                .format(TimeOffDateFormatDashes),
        })
            .where('timeOffTypeId', timeOffTypeAndPolicyWithBalance.timeOffType.id)
            .where('isPending', false)
            .where('employeeId', this.employee.id)
            .get();
    }

    calculateValueForYear(daysAccruedPerPeriod: number, accrualFrequency: string): string {
        return (daysAccruedPerPeriod * this.accrualFrequencies[accrualFrequency]).toFixed(DECIMAL_PLACES);
    }

    accumulateValues<T>(amounts: T[], attributeToSum: string): number {
        return amounts
            .map((item) => item[attributeToSum])
            .reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0);
    }

    getBalance(): number {
        return this.timeOffTypeAndPolicyWithBalance?.timeOffType.displayInHours
            ? this.timeOffTypeAndPolicyWithBalance?.timeOffBalance.balanceHours
            : this.timeOffTypeAndPolicyWithBalance?.timeOffBalance.balance;
    }

    getBaseAccrual(timeOffPolicy: TimeOffPolicy): Translatable {
        if (timeOffPolicy?.isUnlimited) {
            if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'day') {
                return 'components.time-off-type-details.unlimitedDaysYear';
            } else if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'hour') {
                return 'components.time-off-type-details.unlimitedHoursYear';
            }

            return `Unlimited ${timeOffPolicy.timeOffType.unitOfTimeSingular}s / year`;
        }

        const calculatedValue = this.calculateValueForYear(
            this.getAccrualValuePerPeriod(timeOffPolicy),
            timeOffPolicy?.accrualFrequency
        );

        if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'day') {
            return {
                key: 'components.time-off-type-details.calculatedDaysYear',
                params: {
                    calculatedValue: calculatedValue,
                },
            };
        } else if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'hour') {
            return {
                key: 'components.time-off-type-details.calculatedHoursYear',
                params: {
                    calculatedValue: calculatedValue,
                },
            };
        }

        return `${calculatedValue} ${timeOffPolicy?.timeOffType.unitOfTimeSingular}s / year`;
    }

    getAccrualMessage(timeOffPolicy: TimeOffPolicy): Translatable {
        const accruedPerPeriod = this.decimalPipe.transform(
            this.getAccrualValuePerPeriod(timeOffPolicy),
            DECIMAL_PLACES_FOR_PIPE
        );

        if (timeOffPolicy?.isUnlimited) {
            if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'day') {
                return 'components.time-off-type-details.unlimitedDaysPerYear';
            } else if (timeOffPolicy.timeOffType.unitOfTimeSingular === 'hour') {
                return 'components.time-off-type-details.unlimitedHoursPerYear';
            }

            return `Unlimited ${timeOffPolicy.timeOffType.unitOfTimeSingular}(s) per year`;
        }

        const calculatedValue = this.calculateValueForYear(
            this.getAccrualValuePerPeriod(timeOffPolicy),
            timeOffPolicy?.accrualFrequency
        );

        if (timeOffPolicy?.timeOffType.unitOfTimeSingular === 'day') {
            return {
                key: 'components.time-off-type-details.calculatedValueDaysPerYear',
                params: {
                    calculatedValue: calculatedValue,
                    accruedPerPeriod: accruedPerPeriod,
                },
            };
        } else if (timeOffPolicy?.timeOffType.unitOfTimeSingular === 'hour') {
            return {
                key: 'components.time-off-type-details.calculatedValueHoursPerYear',
                params: {
                    calculatedValue: calculatedValue,
                    accruedPerPeriod: accruedPerPeriod,
                },
            };
        }

        return `${calculatedValue} ${timeOffPolicy?.timeOffType.unitOfTimeSingular}(s) per year, i.e ${accruedPerPeriod} per period`;
    }

    getAccrualValuePerPeriod(timeOffPolicy: TimeOffPolicy): number {
        return getTime(timeOffPolicy?.daysAccruedPerPeriod, timeOffPolicy?.timeOffType);
    }

    getAccruesPerPeriod(seniorityOverride: TimeOffSeniorityOverride, timeOffPolicy: TimeOffPolicy): number {
        return getTime(
            AccrualFrequencies[timeOffPolicy.accrualFrequency] * seniorityOverride.daysAccruedPerPeriod,
            timeOffPolicy?.timeOffType
        );
    }

    flagActiveSeniorityOverride(): void {
        let activeSeniorityOverride: TimeOffSeniorityOverride;
        const diffInYears = moment().diff(this.employee.hiredAt, 'years');

        this.timeOffPolicy.timeOffSeniorityOverrides.forEach((seniorityOverrides: TimeOffSeniorityOverride) => {
            if (diffInYears < seniorityOverrides.afterNumberOfYears) {
                return;
            }

            if (
                activeSeniorityOverride &&
                !(seniorityOverrides.afterNumberOfYears > activeSeniorityOverride.afterNumberOfYears)
            ) {
                return;
            }

            // Set the previous active seniority override to false before setting it to the new one
            if (activeSeniorityOverride) {
                activeSeniorityOverride.active = false;
            }

            // Set the new current seniority override
            seniorityOverrides.active = true;
            activeSeniorityOverride = seniorityOverrides;
        });
    }

    closeExpansionPanel(): void {
        this.matExpansionPanel?.close();
    }

    getSortedTimeOffSeniorityOverrides(): TimeOffSeniorityOverride[] {
        return this.timeOffPolicy?.timeOffSeniorityOverrides.sort((a, b) =>
            a.afterNumberOfYears < b.afterNumberOfYears ? -1 : 1
        );
    }
}

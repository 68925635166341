import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, isNil } from 'lodash-es';
import { NumberFormat } from './types';

@Pipe({ name: 'i18nNum', standalone: true, pure: false })
export class LocalizedNumberPipe implements PipeTransform {
    value: string = '';
    lastValue: number | string | null | undefined;
    lastFormat: NumberFormat | undefined;

    private _currencyPipe: CurrencyPipe;

    constructor(
        private translateService: TranslateService,
        private _ref?: ChangeDetectorRef
    ) {
        this._currencyPipe = new CurrencyPipe(this.translateService.currentLang);
        this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe((newLang) => {
            this._currencyPipe = new CurrencyPipe(newLang.lang);
            this.lastValue = null;
            this._ref?.markForCheck();
        });
    }

    transform(
        value: number | string | null | undefined,
        format?: NumberFormat,
        decimalOptions?: { minDecimal?: number; maxDecimal?: number } | undefined
    ): string | null {
        if (isNil(value)) {
            return null;
        }

        // Nothing has changed
        if (isEqual(value, this.lastValue) && isEqual(format, this.lastFormat)) {
            return this.value;
        }

        this.lastValue = value;
        this.lastFormat = format;

        let returnValue = '';

        if (format?.includes('$')) {
            returnValue =
                this._currencyPipe.transform(
                    value,
                    'CAD',
                    format === '$' ? 'symbol-narrow' : '',
                    `1.${decimalOptions?.minDecimal ?? 2}-${decimalOptions?.maxDecimal ?? 2}`,
                    this.translateService.currentLang
                ) ?? '';
        } else {
            if (this.translateService.currentLang === 'en_CA') {
                returnValue = value.toString(); // Return as is
            } else {
                returnValue = value.toString().replace(',', ' ').replace('.', ',');
            }

            if (format === '%') {
                // Remove the percentage sign if it's already there
                returnValue.replace('%', '');
                returnValue = this.translateService.currentLang === 'fr_CA' ? `${returnValue} %` : `${returnValue}%`;
            }
        }

        this.value = returnValue;
        return this.value;
    }
}

import { ModelMixin } from '../core/base-generic.model';

export class IntegrationType extends ModelMixin<IntegrationType>() {
    protected static _type = 'integrationTypes';

    protected static _resource = 'commonComponents/integrationTypes';

    protected static _version = 'v2';

    public isLoading = true;

    get name(): string {
        return this._attributes['name'];
    }

    get label(): string {
        return this._attributes['label'];
    }

    get logo(): string {
        return this._attributes['logo'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get assetBaseURL(): string {
        return 'assets/logos/';
    }

    /**
     * Can be used to disallow an integration from a user
     */
    disabled = false;
}

import { Component, ElementRef, Input } from '@angular/core';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { PayrollFilterableEmployee } from '@app/models/payroll/payroll-filterable-employee.model';
import { AuthService } from '@app/services/auth.service';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Employee } from '@models/employee/employee.model';
import { differenceBy } from 'lodash-es';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';

@Component({
    selector: 'app-common-bulk-select-payroll-employee-dialog',
    templateUrl: '../bulk-select-base-dialog/bulk-select-base-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectPayrollEmployeeDialogComponent<
    ShouldResolveGroupedModels extends boolean | undefined = undefined,
> extends BulkSelectBaseDialogComponent<ShouldResolveGroupedModels> {
    @Input() label = 'components.bulk-select-employee-dialog.label';
    @Input() headerText = 'components.bulk-select-employee-dialog.header';
    @Input() defaultFilters = {};

    filters: any = {
        departmentId: 'applicant-tracker.departments',
        jobIds: 'applicant-tracker.positions',
        status: 'applicant-tracker.status',
    };
    filterValues: any = {
        departmentId: {},
        jobIds: {},
        status: {
            active: 'applicant-tracker.active',
            onboarding: 'applicant-tracker.onboarding',
        },
    };
    activeFilters: any = {};
    defaultFilterValues: any = {};

    items: Employee[] = [];
    selectItems: Employee[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService
    ) {
        super(element, auth);

        // Add terminated filter option if administrator
        if (this.auth.isAdmin()) {
            this.filterValues.status['terminated'] = 'applicant-tracker.terminated';
        }
        this.filterValues.status['onLeave'] = 'employeeStatus.onLeave';
    }

    ngOnInit(): void {
        if (this.defaultFilters && Object.keys(this.defaultFilters).length) {
            this.defaultFilterValues = this.defaultFilters;
        }
    }

    selectAll(): void {
        let request = PayrollFilterableEmployee.param('company', this.auth.company.id)
            .where('onPayroll', 1)
            .with('account')
            .with('department')
            .with('job')
            .limit(0);

        request = this.applyDefaultFilters(request);
        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .all()
            .then(([employees]) => {
                this.selectItems = [...differenceBy(employees, this.selectItems, 'id'), ...this.selectItems];
                this.updateModelSelection();
            })
            .catch(() => {});
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadDepartments();
        this.loadPositions();
    }

    protected loadItems(): void {
        let request = PayrollFilterableEmployee.param('company', this.auth.company.id)
            .where('onPayroll', 1)
            .with('account')
            .with('department')
            .with('job')
            .whereIn('status', ['onboarding', 'active'])
            .page(this.currentPage);

        request = this.applyDefaultFilters(request);
        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([employees, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = employees;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    private loadDepartments(): void {
        Department.param('company', this.auth.company.id)
            .all()
            .then(([departments]) => {
                for (const department of departments) {
                    this.filterValues.departmentId[department.id] = department.name;
                }
            });
    }

    private loadPositions(): void {
        Job.param('company', this.auth.company.id)
            .all()
            .then(([jobs]) => {
                for (const job of jobs) {
                    this.filterValues.jobIds[job.id] = job.title;
                }
            });
    }

    private applyDefaultFilters(request: QueryFetcher): QueryFetcher {
        if (this.defaultFilterValues && Object.keys(this.defaultFilterValues).length) {
            for (const field in this.defaultFilterValues) {
                request = request.where(field, this.defaultFilterValues[field]);
            }
        }
        return request;
    }
}

<div *ngIf="countries && countries.length" style="display: inline-flex; width: 100%">
    <div *ngIf="getValueOptionFlagCode()">
        <i class="{{ getValueOptionFlagCode() }} flag"></i>
    </div>

    <input
        #countryInput
        type="text"
        [placeholder]="placeholder"
        aria-label="country select"
        [class.read-only]="readOnly"
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        (blur)="onBlur()"
    />
    <mat-autocomplete
        #auto="matAutocomplete"
        class="input-anchored"
        [autoActiveFirstOption]="true"
        (optionSelected)="selected($event)"
        [displayWith]="displayFn"
    >
        <mat-option *ngFor="let option of options" [value]="option.value">
            <i class="{{ option.code }} flag"></i>{{ option.label }}
        </mat-option>
    </mat-autocomplete>

    <img *ngIf="loading" class="loading" appIcon="Spinner" />
</div>

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { timeInQuarterHours } from '@app/constants';
import { Locale } from '@app/types/translatable.type';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { fromDisplayFormat } from './from-display-format';
import { toDisplayFormat } from './to-display-format';

const DEBOUNCE_TIME = 50;

@Component({
    selector: 'ui-time-picker',
    templateUrl: './time-picker.template.html',
    styleUrls: ['./time-picker.style.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: TimePickerComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class TimePickerComponent implements MatFormFieldControl<string>, ControlValueAccessor {
    static nextId = 0;
    id = `timepicker-${TimePickerComponent.nextId++}`;
    controlType: string;
    stateChanges = new Subject<void>();
    loading = false;
    /*
     * BlurLock is added so we can prevent the blur event from firing before the
     * selection event, doing so allows the selection event to update with the correct
     * time chosen by the user instead of the blur event coercing the input value first.
     */
    blurLock = false;
    inputControl: FormControl<string | null> = new FormControl();
    onChange = (_: any) => {};
    onTouched = () => {};
    describedBy = '';
    focused = false;
    value: string;
    locale = this.translateService.currentLang as Locale;
    times: string[] = timeInQuarterHours(this.locale);
    filteredTimes: Observable<string[]>;
    displayFn = (time: string): string => {
        if (!time) {
            return;
        }

        return toDisplayFormat(time, this.locale);
    };

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get readOnly() {
        return this._readOnly;
    }
    set readOnly(value) {
        this._readOnly = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input() get errorState(): boolean {
        return (
            this.ngControl &&
            this._defaultErrorStateMatcher.isErrorState(this.ngControl.control as FormControl, this._parentForm)
        );
    }

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this.inputControl.disabled;
    }
    set disabled(value: boolean) {
        const disable = coerceBooleanProperty(value);
        if (disable) {
            this.inputControl.disable();
        } else {
            this.inputControl.enable();
        }

        this.stateChanges.next();
    }

    get empty() {
        return !this.inputControl.value;
    }

    protected _placeholder: string;
    protected _required = false;
    protected _readOnly = false;
    protected _disabled = false;
    protected _inputElement: HTMLInputElement = null;
    private _destroyed: Subject<null> = new Subject();

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor,
        private translateService: TranslateService
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.fm
            .monitor(this.elementRef.nativeElement, true)
            .pipe(takeUntil(this._destroyed))
            .subscribe((origin) => {
                if (this._disabled) {
                    return;
                }
                this.focused = !!origin;
                this.stateChanges.next();
            });

        // Need to do this to show mat-errors when form is submitted.
        if (this._parentForm) {
            this._parentForm.ngSubmit.pipe(takeUntil(this._destroyed)).subscribe(() => {
                this.stateChanges.next();
            });
        }
    }

    ngOnInit(): void {
        this._inputElement = this.elementRef.nativeElement.querySelector('input');

        this.filteredTimes = this.inputControl.valueChanges.pipe(
            startWith(''),
            debounceTime(DEBOUNCE_TIME),
            takeUntil(this._destroyed),
            map((value) => this._filter(value))
        );
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
        this.stateChanges.complete();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    writeValue(value: string): void {
        this.inputControl.setValue(value);
        this.stateChanges.next();
    }

    onContainerClick(event: MouseEvent): void {
        if (this._disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
    }

    selected({ option: { value } }: MatAutocompleteSelectedEvent): void {
        this.value = value;
        this.emitValue();
        this.blurLock = false;
    }

    onBlur(): void {
        if (this.blurLock) {
            return;
        }
        if (this.inputControl.value && this.inputControl.value.trim() !== '') {
            const coercedTime = toDisplayFormat(this.inputControl.value, this.locale);
            this.value = coercedTime;
        }

        this.writeValue(this.value);
        this.emitValue();
    }

    private emitValue(): void {
        const properTimeFormat = fromDisplayFormat(this.value, this.locale);
        this.onChange(properTimeFormat);
    }

    private _filter(userInput: string): string[] {
        if (!userInput || userInput.trim() === '') {
            return this.times;
        }

        const normalized = userInput.replace(':', '').toLowerCase();
        return this.times.filter((time: string) => time.replace(':', '').toLowerCase().includes(normalized));
    }
}

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const ZENDESK_URLS = {
    SNIPPET: 'https://static.zdassets.com/ekr/snippet.js?key=',
    DOMAIN_CHECK: 'https://ekr.zdassets.com/compose/',
};

@Injectable({ providedIn: 'root' })
export class ZenDeskService {
    public isEnabled!: Observable<boolean | undefined>;

    private integrationId?: string;
    private isEnabled$ = new BehaviorSubject<boolean | undefined>(undefined);

    constructor(private httpBackend: HttpBackend) {
        this.isEnabled = this.isEnabled$.asObservable();
        new HttpClient(this.httpBackend)
            .get<{ products?: ZenDeskProduct[] | undefined }>(
                `${ZENDESK_URLS.DOMAIN_CHECK}${environment.zenDesk?.apiKey}`
            )
            .pipe(take(1))
            .subscribe(
                (response) => {
                    // If the products array has length then the ZenDesk widget is enabled for the current environment
                    if (response?.products?.length) {
                        this.isEnabled$.next(true);
                        this.integrationId = response.products.find(
                            (product) => product.name === 'web_widget'
                        )?.bootstrap?.config?.messenger?.integrationId;
                    } else {
                        this.isEnabled$.next(false);
                    }
                },
                () => this.isEnabled$.next(false) // If the API call fails, don't display the widget
            );
    }

    /**
     * A user's language/locale is set at session start and cannot be changed without creating a new session.
     * The only way to clear a user's session is by removing the sessionStorage variable.
     * By clearing the session we're able to reset the chat bot language between English and French for users.
     */
    clearSession(): void {
        if (this.integrationId) {
            sessionStorage.removeItem(`${this.integrationId}.sessionToken`);
        }
    }
}

/**
 * This is not an officially maintained API by ZenDesk, therefore it could be changed at any time by them which would break this functionality.
 * Unfortunately, they don't provide a public API for clearing their sessionStorage variables so this is our best option.
 * This is not the entire response type, but it's all we need. All properties have been made optional as a safeguard in case they DO change the shape of this API response.
 *
 * We use this HTTP response to get the integrationId which is the key for sessionStorage
 */
type ZenDeskProduct = {
    name: string;
    bootstrap?: {
        config?: {
            messenger?: {
                integrationId?: string;
            };
        };
    };
};

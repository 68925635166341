import { Locale } from '@app/types/translatable.type';
import moment from 'moment';

export const timeInQuarterHours = (locale: Locale = 'en_CA'): string[] => {
    const hoursInDay = 24;
    const quartersInDay = 4 * hoursInDay;
    const minutesInQuarter = 15;
    const m = moment().startOf('day').subtract(minutesInQuarter, 'minutes');

    return [...Array(quartersInDay)].map(() => {
        m.add(minutesInQuarter, 'minutes');
        return locale === 'fr_CA' ? m.format('H:mm') : m.format('h:mma');
    });
};

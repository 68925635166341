import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from '@app/types/translatable.type';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'ordinalNumber' })
export class OrdinalNumberPipe implements PipeTransform {
    private readonly SUFFIXES: Record<Locale, Map<string, string>> = {
        en_CA: new Map([
            ['one', 'st'],
            ['two', 'nd'],
            ['few', 'rd'],
            ['other', 'th'],
        ]),
        fr_CA: new Map([
            ['one', 'er'],
            ['other', 'e'],
        ]),
    };

    constructor(private translateService: TranslateService) {}

    transform(number: number): string {
        const localeCode = this.translateService.currentLang as Locale;
        const pr = new Intl.PluralRules(localeCode.replace('_', '-'), { type: 'ordinal' });

        const rule = pr.select(number);
        const suffix = this.SUFFIXES[localeCode]?.get(rule);

        return `${number}${suffix}`;
    }
}

<!-- Back Button

The back button SHOULD be included via composition (ie. <ng-content select="app-back-button"></ng-content>) like it is in segment-header.
However, this would be a large scale migration so for now the input properties are just repeated and propagated down to the child app-back-button component
-->
@if (back) {
    <app-back-button [backRoute]="backRoute" [onBackClick]="onBackClick">
        <ng-content select=".back-container-content" ngProjectAs=".back-container-content"></ng-content>
    </app-back-button>
} @else {
    <ng-content select="app-back-button"></ng-content>
}

<div class="title-container">
    <!-- Title -->
    <div class="flex">
        <h1 id="page-title" *ngIf="!noTitle" class="title" [class.ellipsis]="hideOverflow">
            {{ title | appTranslate }}

            <!-- Badge -->
            <ng-container *ngIf="badgeOptions">
                <ui-badge [type]="badgeOptions.type"> {{ badgeOptions.label | appTranslate }} </ui-badge>
            </ng-container>

            <!--Subtitle (always below south)-->
            <div *ngIf="subtitle" class="sub header">{{ subtitle | appTranslate }}</div>
        </h1>
        <ng-content select=".header-content"></ng-content>
    </div>
    <div class="flex middle header-buttons-container">
        <div class="ui buttons-container">
            <ng-content select=".buttons"> </ng-content>
        </div>

        <!-- Semantic Context Menu -->
        <div
            *ngIf="contextMenu && contextMenuType === 'semantic'"
            class="context-menu ui icon dropdown basic button"
            role="button"
            aria-haspopup="true"
        >
            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
            <div class="left menu">
                <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
            </div>
        </div>

        <!-- Material Context Menu -->
        <ng-container *ngIf="contextMenu && contextMenuType === 'material'">
            <ui-button class="context-menu" type="ghost" [matMenu]="contextMatMenu">
                <div class="sr-only">{{ title | appTranslate }} {{ 'screen-reader-only.moreActions' | translate }}</div>
                <mat-icon svgIcon="meatballHorizontal"></mat-icon>
            </ui-button>
            <mat-menu #contextMatMenu>
                <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
            </mat-menu>
        </ng-container>
    </div>
</div>

<!-- Tab Bar -->
@if (menuService) {
    <div class="tab-bar-container">
        <app-tab-bar aria-labelledby="page-title" [menuService]="menuService"></app-tab-bar>
    </div>
}

<ng-template #dropdownMenu><ng-content></ng-content></ng-template>

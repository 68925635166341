import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { AuthService, FileHelperService, NotifyService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class BenefitEmployeesCsvService {
    constructor(
        private fileHelper: FileHelperService,
        private auth: AuthService,
        private http: HttpClient,
        private notify: NotifyService
    ) {}

    async downloadTemplate(benefitId: number): Promise<void> {
        const templatePath = this.downloadTemplatePath(benefitId);
        return new Promise((resolve, reject) => {
            this.fileHelper.savePayrollFile(
                templatePath,
                `benefits-employees-template`,
                'csv',
                null,
                (e?: Record<string, unknown>) => {
                    if (e) {
                        this.notify.error(ErrorParser.parse(e));
                        reject(e);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    async upload(benefitId: number, file: File): Promise<void> {
        const uploadData = new FormData();
        uploadData.append('file', file);
        const url = this.uploadCsvPath(benefitId);

        try {
            const result = await this.http.post(url, uploadData).toPromise();

            const message = result['data']['attributes']['message'];

            this.notify.success(message);
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 422) {
                throw e.error.errors[0].detail;
            }

            throw e;
        }
    }

    private downloadTemplatePath(benefitId: number): string {
        return `${this.auth.company.id}/benefits/${benefitId}/downloadCsvTemplate`;
    }

    private uploadCsvPath(benefitId: number): string {
        return PayrollResources.BenefitCsvImporter.replace(':companyId', this.auth.company.id.toString()).replace(
            ':benefitId',
            benefitId.toString()
        );
    }
}

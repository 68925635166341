import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, NgForm } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DEFAULT_GENDERS_LIST } from '@app/constants/default-genders-list';
import { Gender, GendersCollection } from '@app/interfaces';

const MAX_USER_DEFINED_GENDERS = 3;

const createEmptyNonUserDefinedGender = (): Gender => {
    return {
        userDefined: false,
        value: null,
    };
};

const createEmptyUserDefinedGender = (): Gender => {
    return {
        userDefined: true,
        value: null,
    };
};

// If the gender does not exist in systemGenders but it exists in selectedGenders anymore, then add it
const filterGendersToAdd = (
    systemGenders: GendersCollection,
    selectedGenders: [{ [key: string]: string }]
): string[] => {
    const gendersToAdd = selectedGenders.filter(
        (selectedGender) => !systemGenders.some((systemGender) => systemGender?.value === selectedGender?.value)
    );

    return gendersToAdd.map((genderToAdd) => genderToAdd?.value);
};

// If the gender exists in systemGenders but it does not exist in selectedGenders anymore, then remove it
const filterGendersToRemove = (
    systemGenders: GendersCollection,
    selectedGenders: [{ [key: string]: string }]
): string[] => {
    const gendersToRemove = systemGenders.filter(
        (systemGender) => !selectedGenders.some((selectedGender) => selectedGender?.value === systemGender.value)
    );

    return gendersToRemove.map((genderToRemove) => genderToRemove?.value);
};

@Component({
    selector: 'add-genders',
    templateUrl: './add-genders.template.html',
    styleUrls: ['./add-genders.style.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AddGendersComponent implements OnInit {
    @Input() initialGenders: GendersCollection;

    @ViewChild('matSelect') matSelect;

    @Output() emitAllGenders: EventEmitter<GendersCollection> = new EventEmitter<GendersCollection>();

    defaultGendersList = DEFAULT_GENDERS_LIST;
    maxUserDefinedGenders = MAX_USER_DEFINED_GENDERS;

    gendersCollection: GendersCollection;

    /**
     * System genders are the genders that are pre-defined genders
     * that can be selected via multi-select dropdown.
     */
    systemGenders: GendersCollection = [];
    userDefinedGenders: GendersCollection = [];

    gendersSelectControl: FormControl<GendersCollection | null> = new FormControl();

    ngOnInit(): void {
        this.populateGenders();
    }

    compareWith(selectedGender: Gender, originalGender: Gender): boolean {
        return selectedGender?.value === originalGender?.value;
    }

    onGendersSelect(event: MatSelectChange): void {
        const selectedGenders: [{ [key: string]: string }] = event.value;

        const gendersToAdd = filterGendersToAdd(this.systemGenders, selectedGenders);
        const gendersToRemove = filterGendersToRemove(this.systemGenders, selectedGenders);

        // Add Genders
        gendersToAdd.forEach((genderToAdd) => {
            if (genderToAdd) {
                const defaultGenderObject = createEmptyNonUserDefinedGender();

                defaultGenderObject.value = genderToAdd;
                this.systemGenders.push(defaultGenderObject);
            }
        });

        // Remove Genders
        gendersToRemove.forEach((genderToRemove) => {
            const index = this.systemGenders.findIndex((systemGender) => systemGender.value === genderToRemove);

            if (index > -1) {
                this.systemGenders.splice(index, 1);
            }
        });

        this.mergeAndEmitGenders();
    }

    onAddUserDefinedGender(): void {
        // Close Mat-Select if the user selected 'I identify as..'
        this.matSelect.close();
        this.gendersSelectControl.setValue(this.systemGenders);

        if (this.userDefinedGenders.length < MAX_USER_DEFINED_GENDERS) {
            this.userDefinedGenders.push(createEmptyUserDefinedGender());
            this.mergeAndEmitGenders();
        }
    }

    removeUserDefinedGender(index: number): void {
        this.userDefinedGenders.splice(index, 1);

        this.mergeAndEmitGenders();
    }

    private mergeAndEmitGenders(): void {
        this.gendersCollection = [...this.systemGenders, ...this.userDefinedGenders];

        this.emitAllGenders.emit(this.gendersCollection);
    }

    private populateGenders(): void {
        if (!this.initialGenders?.length) {
            return;
        }

        this.systemGenders = this.initialGenders.filter((gender: Gender) => !gender.userDefined);
        this.userDefinedGenders = this.initialGenders.filter((gender: Gender) => gender.userDefined);

        this.gendersSelectControl.setValue(this.systemGenders);
    }
}

import { Paginator } from '@app/classes';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { TaxForm } from '@app/models/payroll/tax-form.abstract.model';
import { TaxReturnSummary } from '@app/models/payroll/tax-return-summary.abstract.model';
import { AppDatePipe } from '@app/pipes';
import { AuthService } from '@app/services';
import { Table } from '@table/classes/table.class';
import { MenuColumnItem } from '@table/interfaces/menu-column-item.interface';
import {
    ApplicableDateRangeColumn,
    EmployeeStatusColumn,
    FormStatusColumn,
    FormTypeColumn,
    NameColumn,
    ProvinceOfEmploymentColumn,
} from './columns';

export type Event = 'view' | 'download';

const menuOptions: MenuColumnItem<Event>[] = [
    { label: 'table.employeeTaxForms.viewDetails', event: 'view' },
    { label: 'table.employeeTaxForms.downloadPreview', event: 'download' },
];

export abstract class EmployeeTaxFormsTable<T extends TaxForm> extends Table<T> {
    showMenuColumnOptions = true;
    readonly PAGE_SIZE = 10;

    abstract type: 'T4' | 'RL1';
    protected abstract query: QueryFetcher<T>;

    constructor(
        protected auth: AuthService,
        protected datePipe: AppDatePipe,
        protected summary: TaxReturnSummary
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new EmployeeStatusColumn(),
            new FormTypeColumn(),
            new ApplicableDateRangeColumn(this.datePipe, { disableSorting: this.type === 'RL1' }),
            new ProvinceOfEmploymentColumn({ disableSortingAndFiltering: this.type === 'RL1' }),
            new FormStatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(this.query, {
            pageSize: this.PAGE_SIZE,
        });
    }

    getMenuColumnOptionsForRow(_: T): MenuColumnItem<Event>[] {
        return menuOptions;
    }

    reload(): void {
        // Re-evaluate query in case the summary ID has changed
        this.paginator.updateQuery(this.query);
        super.reload();
    }
}

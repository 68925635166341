import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { AppTranslatePipe } from '@app/pipes/app-translate.pipe';
import { Translatable } from '@app/types/translatable.type';

@Pipe({ name: 'formErrors', standalone: true })
export class FormErrorsPipe extends AppTranslatePipe implements PipeTransform {
    errorMessages: { [key: string]: Translatable } = {
        required: 'forms.errorsPipe.required',
        minlength: 'forms.errorsPipe.minlength',
        maxlength: 'forms.errorsPipe.maxlength',
        pattern: 'forms.errorsPipe.pattern',
        mismatch: 'forms.errorsPipe.mismatch',
        unavailable: 'forms.errorsPipe.unavailable',
        email: 'forms.errorsPipe.email',
        invalidCharacters: 'forms.errorsPipe.invalidCharacters',
    };

    transform(errorsObj: ValidationErrors | null): string {
        if (!errorsObj) {
            return '';
        }

        return this.getErrorMsg(errorsObj);
    }

    getErrorMsg(errorsObj: ValidationErrors): string {
        const errorKeys: string[] = Object.keys(errorsObj);
        for (const key of errorKeys) {
            if (key in this.errorMessages) {
                return super.transform(this.errorMessages[key]);
            }

            switch (key) {
                case 'min':
                    return errorsObj.min.message
                        ? errorsObj.min.message
                        : super.transform({ key: 'forms.errorsPipe.min', params: { min: errorsObj.min.min } });
                case 'max':
                    return errorsObj.max.message
                        ? errorsObj.max.message
                        : super.transform({ key: 'forms.errorsPipe.max', params: { max: errorsObj.max.max } });
                case 'dayMonthString':
                    return errorsObj.dayMonthString.message
                        ? errorsObj.dayMonthString.message
                        : super.transform('forms.errorsPipe.dayMonthString');
                case 'dataFieldTypeRules':
                    return errorsObj.dataFieldTypeRules;
                case 'custom':
                    return errorsObj.custom.message ? super.transform(errorsObj.custom.message) : '';
                default:
                    return '';
            }
        }
    }
}

import { Component, Input, forwardRef } from '@angular/core';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-month-year-picker',
    templateUrl: './month-year-picker.template.html',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthYearPickerComponent),
            multi: true,
        },
    ],
})
export class MonthYearPickerComponent implements ControlValueAccessor {
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() name: string = '';

    date = new FormControl<Moment | null>(null);

    setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>): void {
        const newValue = moment().month(normalizedMonthAndYear.month()).year(normalizedMonthAndYear.year()).date(1);
        this.date.setValue(newValue);
        this.onChange?.(newValue);
        datepicker.close();
    }

    onDatePickerChange(date: Moment): void {
        this.date.setValue(date);
        this.onChange(date);
    }

    writeValue(value: Moment | null): void {
        this.date.setValue(value ? moment(value).date(1) : null);
    }

    registerOnChange(fn: (value: Moment | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.date.disable() : this.date.enable();
    }

    private onChange: (value: Moment | null) => void = () => {};

    private onTouched: () => void = () => {};
}

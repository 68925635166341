import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnChanges, Optional, SecurityContext, Self, SimpleChange } from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Employee } from '@app/models/employee/employee.model';
import { AuthService, FileHelperService } from '@app/services';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { TranslateService } from '@ngx-translate/core';
import { differenceWith } from 'lodash-es';
import { debounceTime, filter } from 'rxjs/operators';
import { BaseAutocompleteComponent, Option as BaseOption } from '../base-autocomplete/base-autocomplete.component';

type AutocompleteValue = Employee | primitive | null;

export interface OptionStatus {
    type: BadgeType;
    label: string;
}

export interface Option extends BaseOption<AutocompleteValue> {
    subTitle?: string;
    status?: OptionStatus;
    hasAvatar?: boolean;
    avatarable?: boolean;
    avatarLabel?: string;
    shouldTranslate?: boolean;
}

export interface SubTitleMap {
    [key: string]: string;
}

/**
 * Resolver should generate a map of employee ids with relevant sub-title values.
 * This is not a perfect solution but is the easiest way to add subtitles to component as it is tightly coupled to employee
 * model currently.
 */
export interface EmployeeSubTitleResolverInterface {
    resolve(employees: Employee[]): Promise<SubTitleMap>;
}

const LEFT_OFFSET = 16;

@Component({
    selector: 'ui-employee-autocomplete',
    templateUrl: './employee-autocomplete.template.html',
    styleUrls: ['./employee-autocomplete.styles.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: EmployeeAutocompleteComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class EmployeeAutocompleteComponent extends BaseAutocompleteComponent<AutocompleteValue> implements OnChanges {
    private readonly CHARACTER_LIMIT = 3;
    private readonly EMPLOYEE_LIMIT = 10;

    controlType = 'employee-autocomplete';
    staticOptions: Option[] = [];
    options: Option[] = [];
    id = `employee-autocomplete-${EmployeeAutocompleteComponent.nextId++}`;
    inputStatusOffset = 0;
    displayFn = (item: AutocompleteValue): string => {
        if (item instanceof Employee) {
            return item.fullName;
        }

        const option = this.staticOptions.find((o: Option) => o.value === item);

        if (option) {
            return option.shouldTranslate ? this.translateService.instant(option.label) : option.label;
        }

        return this.allowNone ? this.translateService.instant(this.noneLabel) : '';
    };

    @Input() statusDeterminer: (employee: Employee) => OptionStatus | null = (_: Employee) => null;
    @Input() customQuery: QueryFetcher;

    /**
     * Provide a function to set the sut title per employee - used when this must be queried from api.
     */
    @Input() subTitleResolver: EmployeeSubTitleResolverInterface;

    @Input() noneLabel = 'none';

    @Input() delayOpen = 0;

    @Input('aria-label') ariaLabel?: string;
    @Input() errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

    /** Allows some employee IDs to be removed from the autocomplete suggestions */
    @Input() filterEmployeeIds?: string[];

    @Input() set additionalOptions(options: Option[]) {
        this._additionalOptions = options;
        this._setStaticOptions();
    }
    get additionalOptions(): Option[] {
        return this._additionalOptions;
    }

    @Input()
    set allowNone(allowNone: boolean) {
        this._allowNone = coerceBooleanProperty(allowNone);

        if (allowNone && typeof this.value === 'undefined') {
            this.writeValue(null);
        }

        this._setStaticOptions();
    }
    get allowNone(): boolean {
        return this._allowNone;
    }

    get value(): AutocompleteValue {
        return this._selection;
    }
    set value(value: AutocompleteValue) {
        if (this._selection === value) {
            return;
        }

        this._selection = value;
        this.onChange(value);
        this.stateChanges.next();
    }

    noneOption: Option | null = null;

    private _allowNone = false;
    private _additionalOptions: Option[] = [];
    private _baseQuery = Employee.param('company', this.auth.company.id);
    private _employeeRetrievalPromise: Promise<Employee[]> = Promise.resolve([]);

    private _subtitleMap: SubTitleMap = {};

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected fm: FocusMonitor,
        protected elementRef: ElementRef<HTMLElement>,
        private auth: AuthService,
        private fileHelper: FileHelperService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService
    ) {
        super(ngControl, _parentForm, _defaultErrorStateMatcher, elementRef, fm);

        // Get new results on typing
        const searchControlSub = this.searchControl.valueChanges
            .pipe(
                debounceTime(500),
                filter((searchTerm) => typeof searchTerm === 'string')
            )
            .subscribe((searchTerm: string) => this._setDisplayOptions(searchTerm));

        this._subscriptions.push(searchControlSub);
    }

    clear(): void {
        this.writeValue(null);
        this._setDisplayOptions();
    }

    ngOnInit(): void {
        this.noneOption = { label: this.noneLabel, value: null, shouldTranslate: true };

        this._inputElement = this.elementRef.nativeElement.querySelector('input');
        this._setStaticOptions();
        this._setDisplayOptions(); // Initial load of employees
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        if (changes['customQuery'] && changes['customQuery'].previousValue !== changes['customQuery'].currentValue) {
            this._setDisplayOptions();
        }
    }

    writeValue(value: AutocompleteValue): void {
        this.value = value;
        this.searchControl.setValue(this.value);
        this.stateChanges.next();
        this._calculateAndSetStatusOffset();
    }

    getValueOptionStatus(): OptionStatus | null {
        if (typeof this.value === 'undefined') {
            return;
        }

        if (this.value instanceof Employee) {
            return this.statusDeterminer(this.value);
        }

        return this.staticOptions.find((option: Option) => option.value === this.value)?.status;
    }

    getAvatarUrl(value: AutocompleteValue): string {
        if (!(value instanceof Employee)) {
            return '';
        }

        return this.fileHelper.serve(value.avatarId);
    }

    protected async _setDisplayOptions(query = ''): Promise<void> {
        this.loading = true;
        let employees: Employee[] = await this._getEmployees(query);
        if (this.subTitleResolver) {
            this._subtitleMap = await this.subTitleResolver.resolve(employees);
        }

        if (this.filterEmployeeIds?.length) {
            employees = this.filterEmployees(employees, this.filterEmployeeIds);
        }

        const employeeOptions: Option[] = employees.map(this._employeeToOption.bind(this));

        const queryFilter = query.trim().toLowerCase();
        const filteredStaticOptions = this.staticOptions.filter((staticOption: Option) =>
            staticOption.label.toLowerCase().includes(queryFilter)
        );
        this.loading = false;
        this.options = [...filteredStaticOptions, ...employeeOptions];
    }

    /**
     * Does what it says on the tin. This is a gross HTML/JS hack to make it work.
     */
    private _calculateAndSetStatusOffset(): void {
        setTimeout(() => {
            const statusDisplay = this.elementRef.nativeElement.querySelector('.input-status'); // ?.getBoundingClientRect().width;
            if (!statusDisplay || !this._inputElement) {
                return;
            }

            /* We need to find out how long the text in the input element is.
             * You can't do that normally with JS/HTML.
             * So we create a dummy element, populate its innerhtml with our text
             * make it invisible, pop it on the screen, get its width, then remove it.
             */
            const text = this.displayFn(this.value);
            const div = document.createElement('div');
            div.style.position = 'fixed';
            div.style.opacity = '0';
            div.style.left = '0';
            div.style.top = '0';
            div.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, text) ?? '';
            document.body.appendChild(div);
            const contentWidth = div.getBoundingClientRect().width;
            document.body.removeChild(div);

            const statusSize = statusDisplay.getBoundingClientRect().width;
            const inputWidth = this._inputElement.getBoundingClientRect().width;

            // Account for status display overflowing the bounds
            if (contentWidth + statusSize > inputWidth) {
                this._inputElement.style.maxWidth = 'calc(100% - ' + (statusSize + LEFT_OFFSET) + 'px)';
                const maxInputWidth = this._inputElement.getBoundingClientRect().width;
                this.inputStatusOffset = maxInputWidth + LEFT_OFFSET;
                return;
            }

            this._inputElement.style.maxWidth = '100%';
            this.inputStatusOffset = contentWidth + LEFT_OFFSET;
        });
    }

    /**
     * Returns the filtered list of employees after removing the provided Ids
     */
    private filterEmployees(employees: Employee[], filteredIds: string[]): Employee[] {
        return differenceWith(employees, filteredIds, (employee, employeeId) => employee.id.toString() === employeeId);
    }

    private _setStaticOptions(): void {
        this.staticOptions = [...(this.allowNone ? [this.noneOption] : []), ...this.additionalOptions];
    }

    private async _getEmployees(queryString = ''): Promise<Employee[]> {
        const query = this.customQuery ? this.customQuery.clone() : this._baseQuery.clone();
        await this._employeeRetrievalPromise;
        return (this._employeeRetrievalPromise = query
            .where('query', queryString)
            .limit(queryString.length < this.CHARACTER_LIMIT ? this.EMPLOYEE_LIMIT : 0)
            .get()
            .then(([employees]: [Employee[], never]) => employees));
    }

    private _employeeToOption(employee: Employee): Option {
        const status = this.statusDeterminer(employee);
        return {
            label: employee.fullName,
            subTitle: this._subtitleMap[employee.id],
            value: employee,
            hasAvatar: !!employee.avatarId,
            avatarable: true,
            avatarLabel: employee.firstName?.charAt(0).toUpperCase() + employee.lastName?.charAt(0).toUpperCase(),
            status,
        };
    }
}

import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { formatLocalizedNumber } from '@app/types/translatable.type';

export class HoursColumn extends Column {
    title = 'table.employeeHour.hours';
    property = 'hours';
    sortField = 'hours';
    sortable = true;
    type = CellDisplay.string;

    constructor() {
        super();
    }

    getDisplayPropertyValue(employeeHour: EmployeeHour): string {
        return (
            formatLocalizedNumber(employeeHour.hours, { locale: employeeHour.locale, decimalPlaces: 2 }) ||
            Number(employeeHour.hours).toFixed(2)
        );
    }
}

import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getHelpCentreArticle, Locale } from '@app/types/translatable.type';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';

/**
 * Gets the localized article within the help centre (English or French)
 */
@Pipe({ name: 'i18nHelpCentre', standalone: true, pure: false })
export class LocalizedHelpCentrePipe implements PipeTransform {
    value: string = '';
    lastLink: string | null | undefined;

    constructor(
        private translateService: TranslateService,
        private _ref?: ChangeDetectorRef
    ) {
        this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.lastLink = null;
            this._ref?.markForCheck();
        });
    }

    transform(link: string | null | undefined): string | null {
        if (!link) {
            return null;
        }

        // Nothing has changed
        if (isEqual(link, this.lastLink)) {
            return this.value;
        }

        this.lastLink = link;

        // Sometimes we link to the help centre for creating new tickets, in which case it's a static URL
        if (link === 'new') {
            this.value = `https://support.humi.ca/hc/${this.translateService.currentLang === 'fr_CA' ? 'fr-ca' : 'en-us'}/requests/new`;
            return this.value;
        }

        const formatCheck = /support.humi.ca\/hc\/(en-us|fr-ca)\/articles\/(\d+)(.*?)(#\S*)?$/.exec(link);

        let articleId = link;

        // If the pipe receives an already fully-formed link (as tested by the RegEx above) then we need to rebuild the URL
        if (formatCheck) {
            // Retrieve ID from link (ie. 4409792231447)
            articleId = formatCheck[2];
            // Check if there was a hash for a specific part of the link (ie. #h_01HR8QRJ7KXZ55CPCH7T0G81WB)
            if (formatCheck[4]) {
                articleId = articleId + formatCheck[4];
            }
        }

        if (articleId) {
            this.value = getHelpCentreArticle(articleId, this.translateService.currentLang as Locale);
        } else {
            // If we weren't able to parse the link for some reason then just return as is
            this.value = link;
        }
        return this.value;
    }
}

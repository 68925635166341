import { Paginator, SortDirection } from '@app/classes';
import { CompanyBenefit } from '@app/models/payroll/company/benefit.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { GlCodeExpenseColumn } from './columns/gl-code-expense.column';
import { GlCodePayableColumn } from './columns/gl-code-payable.column';
import { NameColumn } from './columns/name.column';
import { TypeColumn } from './columns/type.column';

const editOption = {
    label: 'edit',
    event: 'edit',
};

const deleteOption = {
    label: 'delete',
    event: 'delete',
};

export class BenefitTable extends Table {
    sortDirection = SortDirection.ASC;
    links = true;
    showMenuColumnOptions = true;
    protected sortProperty = 'name';

    constructor(
        protected auth: AuthService,
        private benefitTypeIdFilter: number[] = []
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const nameColumn = new NameColumn();
        this.sortColumn = nameColumn;
        this.columns = [nameColumn, new TypeColumn(), new GlCodeExpenseColumn(), new GlCodePayableColumn()];
    }

    setDataSource(): void {
        const query = CompanyBenefit.param('company', this.auth.company.id).with('benefit_type');

        if (this.benefitTypeIdFilter.length) {
            query.where('benefit_type_ids', this.benefitTypeIdFilter.join(','));
        }

        this.paginator = new Paginator(query);
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    getLink(row: CompanyBenefit): (string | number)[] | null {
        return ['/payroll', 'benefits', row.id];
    }
}

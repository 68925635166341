import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { YearEndAdjusterPayStub } from '@app/models/payroll/year-end-adjuster-paystub.model';
import { AuthService } from '@app/services';
import { Jurisdiction } from '@app/types/province';
import {
    AdjustableItemPayload,
    AdjustableItemPayloadAttributes,
    AdjustableItemPayloadParams,
    TaxAdjusterTable,
} from '../interfaces/tax-adjuster.interface';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class TaxAdjusterService {
    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {}

    formatTaxAdjusterSaveResponse(response: AdjustableItemPayloadAttributes): AdjustableItemPayloadParams {
        const responseId = parseInt(response.id, 10);
        const formattedResponse = response['attributes'];
        formattedResponse.id = responseId;
        return formattedResponse;
    }

    async fetchTaxAdjusterTable(year: number): Promise<TaxAdjusterTable> {
        let params = new HttpParams();
        params = params.append('year', year);
        const url = PayrollResources.TaxAdjusterTable;
        const taxAdjusterPromise = await this.http.get(url, { params: params }).toPromise();
        const taxAdjusterTable = taxAdjusterPromise['data']['attributes'];
        return taxAdjusterTable;
    }

    async fetchYearEndAdjustmentPayrollTable(payrollId: string): Promise<TaxAdjusterTable> {
        const url = PayrollResources.YearEndAdjustmentPayrolls + '/' + payrollId + '/table';
        const taxAdjusterPromise = await this.http.get(url).toPromise();
        const taxAdjusterTable = taxAdjusterPromise['data']['attributes'];
        return taxAdjusterTable;
    }

    async fetchTaxAdjusterTableEditable(payrollId: number, minified: boolean): Promise<boolean> {
        let params = new HttpParams();
        params = params.append('minified', minified);
        const url = PayrollResources.YearEndAdjustmentPayrolls + '/' + payrollId + '/table';
        const taxAdjusterEditablePromise = await this.http.get(url, { params: params }).toPromise();
        const taxAdjusterTableEditable = taxAdjusterEditablePromise['data']['attributes']['table_editable'];
        return taxAdjusterTableEditable;
    }

    async savePayableItem(payload: AdjustableItemPayload, id: number | null): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.PayabaleItemsSave;
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveDeductionItem(payload: AdjustableItemPayload, id: number | null): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.DeductionItemsSave.replace(':company', this.auth.company.id.toString());
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveAdditionalPayItem(
        payload: AdjustableItemPayload,
        id: number | null
    ): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.AdditionalPayItemsSave.replace(':company', this.auth.company.id.toString());
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveCompanyContributionBenefitItem(
        payload: AdjustableItemPayload,
        employeeId: number,
        employeeBenefitId: number,
        id: number | null
    ): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.CompanyContirbutionBenefitItemsSave.replace(
            ':employee',
            employeeId.toString()
        ).replace(':employeeBenefitId', employeeBenefitId.toString());
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveEmployeeBenefitDeductionItem(
        payload: AdjustableItemPayload,
        employeeId: number,
        employeeBenefitId: number,
        id: number | null
    ): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.EmployeeBenefitDeductionSave.replace(':employee', employeeId.toString()).replace(
            ':employeeBenefitId',
            employeeBenefitId.toString()
        );
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveEmployeeDeductionDeductionItem(
        payload: AdjustableItemPayload,
        employeeId: number,
        employeeDeductionId: number,
        id: number | null
    ): Promise<AdjustableItemPayloadParams> {
        const url = PayrollResources.EmployeeDeductionDeductionSave.replace(':employee', employeeId.toString()).replace(
            ':employeeDeductionId',
            employeeDeductionId.toString()
        );
        if (!id) {
            const response = await this.http.post(url, payload).toPromise();
            const formattedResponse = this.formatTaxAdjusterSaveResponse(response['data']);
            return formattedResponse;
        }
        await this.http.put(`${url}/${id}`, payload).toPromise();
    }

    async saveEmployeeBox45(employeeId: number, year: number, value: number): Promise<void> {
        await this.http
            .post(PayrollResources.GenericT4Box, {
                data: {
                    type: 'cra_forms_generic_t4_boxes',
                    attributes: {
                        employeeId: employeeId,
                        year: year,
                        value: value,
                        code: 45,
                    },
                },
            })
            .toPromise();
    }

    /**
     * Adds an employee pay stub to the year-end adjuster
     */
    async addTaxFormToYearEndAdjuster({
        employeeId,
        jurisdiction,
        year,
    }: {
        employeeId: string;
        jurisdiction: Jurisdiction;
        year: number;
    }): Promise<void> {
        await new YearEndAdjusterPayStub({ employeeId, jurisdiction, year }).save();
    }
}

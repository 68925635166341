import { Injectable, OnDestroy } from '@angular/core';
import { UserAbilities } from '@app/interfaces';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { PayrollSettingsService } from './payroll-settings.service';

@Injectable({
    providedIn: 'root',
})
/**
 * Determines if the current user has access to certain parts of the system based on system state
 */
export class AbilityService implements UserAbilities, OnDestroy {
    abilitiesChanged$ = new Subject<void>();
    private isPayrollSyncEnabled = false;
    private payrollSubscription: Subscription;

    constructor(
        private auth: AuthService,
        private payrollSettingsService: PayrollSettingsService
    ) {
        this.isPayrollSyncEnabled = Boolean(this.auth.company.isPayrollSyncEnabled);
        this.payrollSubscription = this.payrollSettingsService.loading$.subscribe((loading) => {
            if (!loading) {
                this.abilitiesChanged$.next();
            }
        });
    }

    ngOnDestroy(): void {
        this.payrollSubscription?.unsubscribe();
    }

    async load(): Promise<void> {
        await this.payrollSettingsService.load();
    }

    all(): { [key in keyof UserAbilities]: boolean } {
        return {
            payroll: this.payroll(),
            paystubs: this.paystubs(),
            myTeam: this.myTeam(),
            vacationYTDNavigable: this.vacationYTDNavigable(),
            payrollSetupNavigable: this.payrollSetupNavigable(),
        };
    }

    payroll(): boolean {
        return this.isPayrollSyncEnabled;
    }

    paystubs(): boolean {
        // Check that the employee has been on Payroll in the past and has a
        // `prEmployeeId`, even if they aren't currently syncing to Payroll.
        const employeeHasPayroll = Boolean(this.auth.employee.prEmployeeId);

        return this.isPayrollSyncEnabled && employeeHasPayroll;
    }

    myTeam(): boolean {
        return this.auth.hasDirectReports;
    }

    vacationYTDNavigable(): boolean {
        return this.payrollSettingsService.isVacationYTDNavigable;
    }

    payrollSetupNavigable(): boolean {
        return this.payrollSettingsService.isPayrollSetupNavigable();
    }
}

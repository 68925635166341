/**
 * FormatLabelPipe
 *
 * This pipe addresses internationalization needs for form labels with colons.
 * Different languages have different typographic rules for how colons should be formatted:
 *
 * - French: Requires a space before colons (e.g., "Ville :")
 * - English: No space before colons (e.g., "City:")
 *
 * Without this pipe, developers would need to:
 * 1. Either hardcode different formats in different language translation files
 * 2. Or use a UI library that might add its own colons, creating issues when translations already include colons
 *
 * This pipe solves these problems by:
 * - Automatically applying the correct format based on the current language
 * - Removing any existing colons to prevent duplicates
 * - Adding properly formatted colons according to language-specific rules
 *
 * Usage:
 * {{ 'translation.key' | translate | i18nLabel }}
 *
 * This approach centralizes the formatting logic, making it consistent across the application
 * and prevents double colons when labels are displayed in components that automatically add colons.
 */
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';

@Pipe({
    name: 'i18nLabel',
    pure: false,
})
export class FormatLabelPipe implements PipeTransform {
    value: string = '';
    lastValue: string | null | undefined;

    constructor(
        private translateService: TranslateService,
        private _ref?: ChangeDetectorRef
    ) {
        this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.lastValue = null;
            this._ref?.markForCheck();
        });
    }

    transform(value: string | null): string {
        if (!value) return '';

        // Nothing has changed
        if (isEqual(value, this.lastValue)) {
            return this.value;
        }

        this.lastValue = value;

        // Remove any existing colon first
        const cleanValue = value.replace(/\s*:$/, '');

        // If the label already ends with a colon, don't add another one
        if (value.trim().endsWith(':')) {
            this.value = value;
            return this.value;
        }

        // Get current language
        const currentLang = this.translateService.currentLang || 'en';

        // Add appropriate spacing based on language
        if (currentLang.startsWith('fr')) {
            // French - space before colon
            this.value = `${cleanValue} :`;
        } else {
            // English and others - no space before colon
            this.value = `${cleanValue}:`;
        }

        return this.value;
    }
}

import { Locale } from '@app/types/translatable.type';

const meridianOffset = 2;
const meridianHours = 12;
const timePadding = 2;

export const fromDisplayFormat = (time: string, locale: Locale = 'en_CA'): string => {
    if (typeof time !== 'string') {
        return '';
    }

    let meridian = locale === 'fr_CA' ? null : time.substring(time.length - meridianOffset);

    let [hours, minutes] = time.replace(/[A-z]/gi, '').split(':');

    if (!meridian) {
        meridian = parseInt(hours) > 12 ? 'pm' : 'am';
    }

    if (isNaN(parseInt(hours)) || isNaN(parseInt(minutes))) {
        return '';
    }

    hours = '' + (parseInt(hours) % meridianHours);
    if (meridian === 'pm') {
        const hoursVal = parseInt(hours) + meridianHours;
        hours = '' + hoursVal;
    }

    if (!minutes) {
        minutes = '0';
    }

    hours = hours.padStart(timePadding, '0');
    minutes = minutes.padStart(timePadding, '0');

    return `${hours}:${minutes}:00`;
};

import { Jurisdiction } from '@app/types/province';
import { ModelMixin } from '../core/base-generic.model';

/**
 * Minimal version of payStub model used by the year-end adjuster to add employees
 */
export class YearEndAdjusterPayStub extends ModelMixin<YearEndAdjusterPayStub>() {
    protected static _resource = 'payroll/yearEndAdjustments/addPaystub';
    protected static _type = 'paystubs';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['year', 'employeeId', 'jurisdiction'];

    set year(year: number) {
        this._attributes['year'] = year;
    }

    set employeeId(employeeId: string) {
        this._attributes['employeeId'] = employeeId;
    }

    set jurisdiction(jurisdiction: Jurisdiction) {
        this._attributes['jurisdiction'] = jurisdiction;
    }
}

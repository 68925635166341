import { TaxForm } from '@app/models/payroll/tax-form.abstract.model';
import { AppDatePipe } from '@app/pipes';
import { ALL_PROVINCES } from '@app/types/province';
import { BadgeColumn } from '@table/classes/badge-column.class';
import { CheckListFilter } from '@table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@table/classes/column.class';
import { CellDisplay } from '@table/enums/cell-display.enum';
import { BadgeDisplay } from '@table/interfaces/badge-display.interface';
import { getMonth, isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';

export class NameColumn extends Column {
    title = 'table.employeeTaxForms.name';
    type = CellDisplay.avatar;
    property = 'employee';
    sortField = 'lastName';
    sortable = true;
}

// This only shows `active`, `onboarding` or `terminated` for now. If we
// want to add `on leave`, we would need to add `withOnLeave` to the
// employees query in the RL1Controller and T4Controllers in the backend.
export class EmployeeStatusColumn extends Column {
    title = 'table.employeeTaxForms.employeeStatus';
    type = CellDisplay.string;
    property = 'employee.status';
    sortable = true;
    sortField = 'terminated';
    filter = new CheckListFilter(
        'terminated',
        [
            { label: 'active', value: 0 },
            { label: 'terminated', value: 1 },
        ],
        this.title
    );
}

export class FormTypeColumn extends Column {
    title = 'table.employeeTaxForms.formType';
    type = CellDisplay.string;

    getDisplayPropertyValue(row: TaxForm): string {
        return `table.employeeTaxForms.${row.isT4() ? 'T4' : 'RL1'}.type`;
    }
}

export class ApplicableDateRangeColumn extends Column {
    title = 'table.employeeTaxForms.applicableDateRange';
    type = CellDisplay.string;
    sortField = 'startDate';

    constructor(
        private datePipe: AppDatePipe,
        { disableSorting } = { disableSorting: false }
    ) {
        super();
        this.sortable = !disableSorting;
    }

    getDisplayPropertyValue(row: TaxForm): string {
        if (row.startDate === undefined || row.endDate === undefined) {
            return row.year.toString();
        }

        if (
            getMonth(row.startDate) === 0 &&
            isFirstDayOfMonth(row.startDate) &&
            getMonth(row.endDate) === 11 &&
            isLastDayOfMonth(row.endDate)
        ) {
            return row.year.toString();
        }

        return `${this.datePipe.transform(row.startDate)} - ${this.datePipe.transform(row.endDate)}`;
    }
}

export class ProvinceOfEmploymentColumn extends Column {
    title = 'table.employeeTaxForms.provinceOfEmployment';
    type = CellDisplay.string;
    property = 'jurisdiction';

    // Allows disabling of filter/sorting on RL-1s since it's irrelevant (always QC)
    constructor({ disableSortingAndFiltering } = { disableSortingAndFiltering: false }) {
        super();
        if (!disableSortingAndFiltering) {
            this.sortable = true;
            this.filter = new CheckListFilter(
                'jurisdiction',
                ALL_PROVINCES.map((prov) => ({ label: `provinces.${prov}`, value: prov })),
                this.title
            );
        }
    }

    getDisplayPropertyValue(row: TaxForm): string {
        return `provinces.${row.jurisdiction}`;
    }
}

export class FormStatusColumn extends BadgeColumn<TaxForm> {
    title = 'table.employeeTaxForms.formStatus';
    property = 'state';
    sortable = true;
    type = CellDisplay.badge;

    filter = new CheckListFilter(
        'state',
        [
            { label: 'table.employeeTaxForms.open', value: 'open' },
            { label: 'table.employeeTaxForms.error', value: 'error' },
            { label: 'table.employeeTaxForms.edited', value: 'edited' },
            // These next two are not actual T4 states, they're T619 states that are denormalized
            // onto the T4 at runtime. For actual query purposes, we need to use the
            // `sent` state (which never actually appears on the frontend)
            { label: 'table.employeeTaxForms.processing', value: 'sent' },
            { label: 'table.employeeTaxForms.complete', value: 'sent' },
        ],
        this.title
    );

    getBadgeDisplay(row: TaxForm): BadgeDisplay | undefined {
        return row.stateBadge;
    }
}

import { Injectable } from '@angular/core';
import { SimpleEntityActions } from '@app/classes';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';
import { ManageCustomFields, ManageRolesAndPermissions } from '@settings/meta/module-feature-permissions.meta';

const SettingsMenu: MenuItem[] = [
    {
        key: 'settings.menu.notifications',
        link: ['/settings', 'notifications'],
    },
    {
        key: 'settings.menu.rolesAndPermissions',
        link: ['/settings', 'roles'],
        permission: [...ManageRolesAndPermissions.permissions],
        permissionAll: true,
    },
    {
        key: 'settings.menu.companyLogo',
        link: ['/settings', 'company-logo'],
    },
    {
        key: 'settings.menu.customFields',
        link: ['/settings', 'custom-fields'],
        permission: [...ManageCustomFields.permissions],
        permissionAll: true,
        children: [
            {
                key: 'settings.menu.fields',
                link: ['/settings', 'custom-fields', 'fields'],
            },
            {
                key: 'settings.menu.fieldGroups',
                link: ['/settings', 'custom-fields', 'field-groups'],
            },
        ],
    },
    {
        key: 'settings.menu.billing',
        permission: [
            ...new SimpleEntityActions('companySubscription').all,
            ...new SimpleEntityActions('companySubscriptionCard').some('create'),
        ],
        permissionAll: true,
        link: ['/settings', 'billing'],
    },
    {
        key: 'settings.menu.integrations',
        link: ['/settings', 'integrations-v2'],
    },
    {
        key: 'settings.menu.signIn',
        link: ['/settings', 'sign-in'],
    },
];

@Injectable()
export class SettingsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = SettingsMenu;
    }
}

import { Translatable, TranslatableKey } from '@app/types/translatable.type';

const TimeOffv3DisplayOptions: { [key: string]: Translatable } = {
    Accrual: 'time-off-v3.timeOffTransactions.accrual',
    'First accrual after new policy was assigned':
        'time-off-v3.timeOffTransactions.firstAccrualAfterANewPolicyWasAssigned',
    'First accrual after seniority increase in annual accrual':
        'time-off-v3.timeOffTransactions.firstAccrualAfterSeniorityIncreaseInAnnualAccrual',
    'First accrual after Policy was edited by admin':
        'time-off-v3.timeOffTransactions.firstAccrualAfterPolicyWasEditedByAdmin',
    'Regular Accrual': 'time-off-v3.timeOffTransactions.regularAccrual',
    // TODO  public const TIME_OFF_REQUEST = 'Usage (Day %d of %d)';
    'Accrual Adjustment': 'time-off-v3.timeOffTransactions.accrualAdjustment',
    'Manual Adjustment': 'time-off-v3.timeOffTransactions.manualAdjustment',
    // TODO public const ADMIN_ADJUSTMENT_DESCRIPTION = '%s%sBy %s';
    // TODO public const YEAR_END_CAP_DESCRIPTION = 'Balance at year end was capped at %d %s.';
    'Balance Cap Adjustment': 'time-off-v3.timeOffTransactions.balanceCapAdjustment',
    // TODO public const BALANCE_LIMIT_DESCRIPTION = 'Balance can go no higher than %d %s.';
    'Year End Cap Adjustment': 'time-off-v3.timeOffTransactions.yearEndCapAdjustment',
    'Expiry Adjustment': 'time-off-v3.timeOffTransactions.expiryAdjustment',
    // TODO public const CARRYOVER_BALANCE_EXPIRY_DESCRIPTION = 'Unused year end carryover expires after %d %s.';
    'Termination Proration': 'time-off-v3.timeOffTransactions.terminationProration',
    'Adjustment to correct for balance proration upon termination.':
        'time-off-v3.timeOffTransactions.adjustmentToCorrectForBalanceProrationUponTermination',
};

const TimeOffv3StatusDisplayOptions: { [key: string]: Translatable } = {
    approved: 'time-off-v3.approved',
    pending: 'time-off-v3.pending',
    denied: 'time-off-v3.denied',
};

const TimeOffTypeUnitOfTimeDisplayOptions: { [key: string]: TranslatableKey } = {
    days: 'time-off-v3.days',
    hours: 'time-off-v3.hours',
};

const TimeOffTypeUnitOfTimeHeaderDisplayOptions: { [key: string]: TranslatableKey } = {
    days: 'time-off-v3.daysAccruedPerYear',
    hours: 'time-off-v3.hoursAccruedPerYear',
};

const TimeOffTypeUnitOfTimeDetailsHeaderDisplayOptions: { [key: string]: TranslatableKey } = {
    days: 'time-off-v3.policyComponents.accrualCard.daysAccruedPerYear',
    hours: 'time-off-v3.policyComponents.accrualCard.hoursAccruedPerYear',
};

const TimeOffv3BalanceDisplayOptions: { [key: string]: Translatable } = {
    '{{ balance }} days': 'time-off-v3.balanceWithDays',
    '{{ balance }} hours': 'time-off-v3.balanceWithHours',
};

const TimeOffv3DateDisplayOptions: { [key: string]: Translatable } = {
    Today: 'components.time-off-policy-card.today',
    'Year End': 'components.time-off-policy-card.yearEnd',
};

const TimeOffv3WorkScheduleDisplayOptions: { [key: string]: Translatable } = {
    'Default Work Schedule': 'time-off-v3.defaultWorkSchedule',
};

const WeekdayDisplayOptions: { [key: string]: Translatable } = {
    Mon: 'time-off-v3.yearCalendar.mon',
    Tue: 'time-off-v3.yearCalendar.tue',
    Wed: 'time-off-v3.yearCalendar.wed',
    Thu: 'time-off-v3.yearCalendar.thu',
    Fri: 'time-off-v3.yearCalendar.fri',
    Sat: 'time-off-v3.yearCalendar.sat',
    Sun: 'time-off-v3.yearCalendar.sun',
};

const HolidayDisplayOptions: { [key: string]: TranslatableKey } = {
    "New Year's Day": 'time-off-v3.yearCalendar.holidays.newYearsDay',
    "New Year's Day observed": 'time-off-v3.yearCalendar.holidays.newYearsDayObserved',
    'Family Day': 'time-off-v3.yearCalendar.holidays.familyDay',
    'Good Friday': 'time-off-v3.yearCalendar.holidays.goodFriday',
    'Victoria Day': 'time-off-v3.yearCalendar.holidays.victoriaDay',
    'Canada Day': 'time-off-v3.yearCalendar.holidays.canadaDay',
    'Labour Day': 'time-off-v3.yearCalendar.holidays.labourDay',
    'Thanksgiving Day': 'time-off-v3.yearCalendar.holidays.thanksgivingDay',
    'Company Anniversary': 'time-off-v3.yearCalendar.holidays.companyAnniversary',
    'Christmas Day': 'time-off-v3.yearCalendar.holidays.christmasDay',
    'Christmas Day observed': 'time-off-v3.yearCalendar.holidays.christmasDayObserved',
    'Boxing Day': 'time-off-v3.yearCalendar.holidays.boxingDay',
    'Remembrance Day': 'time-off-v3.yearCalendar.holidays.remembranceDay',
    'British Columbia Day': 'time-off-v3.yearCalendar.holidays.britishColumnbiaDay',
    "Day After New Year's Day": 'time-off-v3.yearCalendar.holidays.dayAfterNewYearsDay',
    'Day After New Year’s Day': 'time-off-v3.yearCalendar.holidays.dayAfterNewYearsDay',
    'Islander Day': 'time-off-v3.yearCalendar.holidays.islanderDay',
    'Nova Scotia Heritage Day': 'time-off-v3.yearCalendar.holidays.novaScotiaHeritageDay',
    'Louis Riel Day': 'time-off-v3.yearCalendar.holidays.louisRielDay',
    'Yukon Heritage Day': 'time-off-v3.yearCalendar.holidays.yukonHeritageDay',
    "St. Patrick's Day": 'time-off-v3.yearCalendar.holidays.stPatricksDay',
    'Easter Monday': 'time-off-v3.yearCalendar.holidays.easterMonday',
    "St. George's Day": 'time-off-v3.yearCalendar.holidays.stGeorgesDay',
    "National Patriots' Day": 'time-off-v3.yearCalendar.holidays.nationalPatriotsDay',
    'National Indigenous Peoples Day': 'time-off-v3.yearCalendar.holidays.nationalIndigenousPeoplesDay',
    'Discovery Day': 'time-off-v3.yearCalendar.holidays.discoveryDay',
    'St. Jean Baptiste Day': 'time-off-v3.yearCalendar.holidays.stJeanBaptisteDay',
    'Memorial Day': 'time-off-v3.yearCalendar.holidays.memorialDay',
    'Nunavut Day': 'time-off-v3.yearCalendar.holidays.nunavutDay',
    "Orangemen's Day": 'time-off-v3.yearCalendar.holidays.orangemensDay',
    'Civic/Provincial Day': 'time-off-v3.yearCalendar.holidays.civicProvincialDay',
    'Saskatchewan Day': 'time-off-v3.yearCalendar.holidays.saskatchewanDay',
    'New Brunswick Day': 'time-off-v3.yearCalendar.holidays.newBrunswickDay',
    'Natal Day': 'time-off-v3.yearCalendar.holidays.natalDay',
    "The Royal St John's Regatta (Regatta Day)": 'time-off-v3.yearCalendar.holidays.theRoyalStJohnsRegatta',
    'Gold Cup Parade': 'time-off-v3.yearCalendar.holidays.goldCupParade',
    'Nunavut Day observed': 'time-off-v3.yearCalendar.holidays.nunavutDayObserved',
    'St. Jean Baptiste Day observed': 'time-off-v3.yearCalendar.holidays.stJeanBaptisteDayObserved',
    'Remembrance Day observed': 'time-off-v3.yearCalendar.holidays.remembranceDayObserved',
    'National Aboriginal Day': 'time-off-v3.yearCalendar.holidays.nationalAboriginalDay',
};

const ApprovalObserverTypeDisplayOptions: { [key: string]: Translatable } = {
    'All Admins': 'time-off-v3.approvalObserverTypes.allAdmins',
    Admin: 'time-off-v3.approvalObserverTypes.admin',
    Manager: 'time-off-v3.approvalObserverTypes.manager',
    "Manager's Manager": 'time-off-v3.approvalObserverTypes.managersManager',
    'Specific Person': 'time-off-v3.approvalObserverTypes.specificPerson',
};

const AccrualTimingDisplayOptions: { [key: string]: TranslatableKey } = {
    'first-day': 'time-off-v3.firstDayOfPeriod',
    'last-day': 'time-off-v3.lastDayOfPeriod',
    'next-first-day': 'time-off-v3.firstDayOfNextPeriod',
};

export {
    AccrualTimingDisplayOptions,
    ApprovalObserverTypeDisplayOptions,
    HolidayDisplayOptions,
    TimeOffTypeUnitOfTimeDetailsHeaderDisplayOptions,
    TimeOffTypeUnitOfTimeDisplayOptions,
    TimeOffTypeUnitOfTimeHeaderDisplayOptions,
    TimeOffv3BalanceDisplayOptions,
    TimeOffv3DateDisplayOptions,
    TimeOffv3DisplayOptions,
    TimeOffv3StatusDisplayOptions,
    TimeOffv3WorkScheduleDisplayOptions,
    WeekdayDisplayOptions,
};
